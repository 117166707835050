import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  ReferenceField,
  useRecordContext,
  useDataProvider,
  useRedirect,
} from "react-admin";
import WinnerPriceIcon from "@mui/icons-material/Paid";
import { app } from "../constants";
import Chip from "@mui/material/Chip";

const WinnerChips = ({ source }) => {
  const record = useRecordContext();
  return (
    <div
      style={{
        display: "inline-block",
      }}
    >
      {record[source].map((item, index) => (
        <Chip
          label={`${index + 1}) ${app.currency}${item}`}
          variant="outlined"
          sx={{ mr: 1 }}
        />
      ))}
    </div>
  );
};
const WinnerPriceList = () => (
  <List>
    <Datagrid>
      <ReferenceField
        source="cricket_week"
        reference={"cricket_weeks"}
        fullWidth
        label="Cricket Week"
      >
        <TextField source="name" />
      </ReferenceField>
      <WinnerChips source="winners" />
      <EditButton />
    </Datagrid>
  </List>
);
const WinnerPriceForm = ({ edit }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (edit) {
      (async () => {
        const { data } = await dataProvider.getOne("cricket_weeks", {
          id: record.cricket_week,
        });
        if (data.closed) {
          notify("Sorry, not able to edit as week is closed.", {
            type: "error",
          });
          redirect("/cricket_winner_prices");
        }
      })();
    }
  }, []);
  return (
    <SimpleForm>
      <ReferenceInput
        source="cricket_week"
        reference={"cricket_weeks"}
        fullWidth
        filter={{ closed: false }}
      >
        <SelectInput {...app.inputAttrs} optionText={"name"} />
      </ReferenceInput>
      <ArrayInput source="winners" label="All Winners">
        <SimpleFormIterator fullWidth>
          <NumberInput {...app.inputAttrs} label={`Prize(${app.currency})`} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
const transformData = (_data, notify) => {
  let data = { ..._data };
  if ((data?.winners || []).length < 3) {
    notify("Please add minimum 3 winner prize.", { type: "error" });
    return Promise.reject();
  }
  if ((data?.winners || []).length > 10) {
    notify("Maximum winner count is 10.", { type: "error" });
    return Promise.reject();
  }
  return data;
};
const WinnerPriceEdit = () => {
  const notify = useNotify();
  return (
    <Edit transform={(data) => transformData(data, notify)}>
      <WinnerPriceForm edit />
    </Edit>
  );
};
const WinnerPriceCreate = () => {
  const notify = useNotify();
  return (
    <Create transform={(data) => transformData(data, notify)}>
      <WinnerPriceForm />
    </Create>
  );
};
export default {
  list: WinnerPriceList,
  edit: WinnerPriceEdit,
  create: WinnerPriceCreate,
  name: "cricket_winner_prices",
  icon: WinnerPriceIcon,
  label: "Cricket Winner Prices",
};
