import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
} from "react-admin";
import FolderIcon from "@mui/icons-material/Folder";
import { app } from "../constants";

const FolderList = () => (
  <List sort={{ field: "createdate", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="folder" label="Folder Title" />
    </Datagrid>
  </List>
);
const FolderForm = () => {
  return (
    <SimpleForm>
      <TextInput source="folder" {...app.inputAttrs} label="Folder Title" />
    </SimpleForm>
  );
};
const FolderCreate = () => {
  const extractCharactersAndDigits = (inputString) => {
    const regex = /[a-zA-Z0-9]+/g;
    const resultArray = inputString.match(regex);
    const resultString = resultArray ? resultArray.join("") : "";
    return resultString;
  };

  const transform = (data) => {
    data.id = data.folder.toLocaleLowerCase();
    data.folder = extractCharactersAndDigits(data.folder);
    return data;
  };
  return (
    <Create transform={transform}>
      <FolderForm />
    </Create>
  );
};
export default {
  list: FolderList,
  create: FolderCreate,
  name: "folders",
  icon: FolderIcon,
  label: "Folders",
};
