import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  UrlField,
} from "react-admin";
import GiftCardIcon from "@mui/icons-material/Cake";

const GiftCardList = () => (
  <List>
    <Datagrid>
      <TextField source="order_no" />
      <TextField source="status" />
      <NumberField source="amount" />
      <TextField source="code" />
      <DateField source="expire_on" label="Expire" />
      <TextField source="reference_id" />
      <UrlField source="link" target="_blank" />
    </Datagrid>
  </List>
);

export default {
  list: GiftCardList,
  name: "gift_cards",
  icon: GiftCardIcon,
  label: "GiftCards",
};
