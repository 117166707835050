import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HowToPlayContent = () => {
  const Title = ({ text }) => {
    return (
      <>
        <Typography className="theme-font bold">{text}</Typography>
        <Divider sx={{ marginBottom: 1 }} light />
      </>
    );
  };
  const Description = ({ text, Text }) => {
    return (
      <Typography
        variant="body1"
        className="theme-font"
        gutterBottom
        align="justify"
      >
        {Text ? <Text /> : text}
      </Typography>
    );
  };
  const AccordionWrapper = ({ Header, Body }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Header />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Body />
        </AccordionDetails>
      </Accordion>
    );
  };
  return (
    <div
      style={{
        padding: "20px 0px",
      }}
    >
      <h3 className="text-align-center" style={{ marginTop: 0 }}>
        How to Enter a Contest?
      </h3>
      <AccordionWrapper
        Header={() => <Title text={"Step 1"} />}
        Body={() => (
          <Description text="Choose any active Contest (or multiple) of your liking and your category of interest. Click “Play” to open the contest." />
        )}
      />
      <AccordionWrapper
        Header={() => <Title text={"Step 2"} />}
        Body={() => (
          <Description text="For that contest, pick an opinion that you think most number of people (Majority) or least number of people (Minority) will end up picking. You can analyze/strategize as per Majority return% or Minority return% stats. These stats are generated on current number of responses." />
        )}
      />
      <AccordionWrapper
        Header={() => <Title text={"Step 3"} />}
        Body={() => (
          <Description text="Next, choose an amount via the amount slider from minimum to maximum range for that contest. Once you are ready to enter the contest, swipe the submission slider at the bottom. Congratulations! You’ve entered the Contest." />
        )}
      />
      <AccordionWrapper
        Header={() => <Title text={"Step 4"} />}
        Body={() => (
          <Description text="Majority Win% or Minority win% stats may change with time depending on other incoming users’ response. You can always re-strategize and edit your response with the latest data. To change your response before a Contest is closed, go to “My plays” and click “Edit” on the contest you wish to change. Repeat Step:2 and Step:3." />
        )}
      />
    </div>
  );
};

export default HowToPlayContent;
