import React from "react";
import TradeIcon from "@mui/icons-material/ShowChart";
import { TradeCreate, TradeList } from "../trades";

export default {
  list: () => <TradeList template_trades />,
  create: () => <TradeCreate template_trades />,
  name: "template_trades",
  icon: TradeIcon,
  label: "Trades",
};
