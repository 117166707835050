import React from "react";
import {
  List,
  Datagrid,
  SimpleForm,
  Create,
  ImageInput,
  ImageField,
  useRecordContext,
  Button,
  useNotify,
  useDataProvider,
  useRedirect,
  TextField,
  ReferenceInput,
  SelectInput,
  Edit,
  EditButton,
  Filter,
  TextInput,
} from "react-admin";
import PermMediaIcon from "@mui/icons-material/OndemandVideo";
import CopyAllIcon from "@mui/icons-material/ContentCopy";
import { app } from "../constants";

const MediaFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search Folder Name"
      source="folder"
      fullWidth={true}
      variant="outlined"
      alwaysOn
    />
    <ReferenceInput
      source="folder"
      reference="folders"
      fullWidth
      alwaysOn
      perPage={10000000}
    >
      <SelectInput optionText={"folder"} variant="outlined" />
    </ReferenceInput>
    <TextInput
      label="Search Media Title"
      source="media.title"
      fullWidth={true}
      variant="outlined"
      alwaysOn
    />
  </Filter>
);

const CopyButton = ({}) => {
  const media = useRecordContext();
  const notify = useNotify();
  return (
    <Button
      endIcon={<CopyAllIcon />}
      onClick={() => {
        navigator.clipboard.writeText(media.media.src);
        notify("Path copied to clipboard.");
      }}
    >
      Copy Path
    </Button>
  );
};
const MediaList = () => (
  <List filters={<MediaFilter />}>
    <Datagrid>
      <TextField source="media.title" />
      <ImageField source="media.src" label="Thumbnail" emptyText="--" />
      <TextField
        source="folder"
        emptyText="Untitled"
        sx={{ textTransform: "uppercase", fontStyle: "italic" }}
      />
      <CopyButton />
      <EditButton />
    </Datagrid>
  </List>
);
const MediaCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleSubmit = async (formData) => {
    notify("Creating media records...");
    await Promise.all(
      formData.media.map((image) =>
        dataProvider.create("media", {
          data: { media: image, folder: formData.folder },
        })
      )
    );
    notify("Records created successfully!", "success");
    redirect("/media");
  };
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <ReferenceInput
          source="folder"
          reference="folders"
          variant="outlined"
          perPage={10000000}
        >
          <SelectInput {...app.inputAttrs} optionText="folder" />
        </ReferenceInput>
        <ImageInput
          source="media"
          label="Media"
          multiple
          accept={["image/*", "video/mp4"]}
        >
          <ImageField source="src" title="Media" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
const MediaEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="folder" reference="folders" fullWidth>
        <SelectInput {...app.inputAttrs} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
export default {
  list: MediaList,
  create: MediaCreate,
  edit: MediaEdit,
  name: "media",
  label: "Media",
  icon: PermMediaIcon,
};
