import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import InExpandIcon from "@mui/icons-material/Remove";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Chip = ({ chipKey, title, subtitle }) => {
    return (
      <Accordion
        expanded={expanded === chipKey}
        onChange={handleChange(chipKey)}
        sx={{
          boxShadow: "none !important",
          margin: "20px 10px 5px 10px",
          "&.Mui-expanded": {
            margin: "20px 10px 5px 10px",
          },
          "&::before": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            expanded === chipKey ? (
              <InExpandIcon
                sx={{
                  color: "#8922B8",
                }}
              />
            ) : (
              <ExpandMoreIcon
                sx={{
                  color: "#8922B8",
                }}
              />
            )
          }
          style={{
            border: "1px solid #E0E0E0",
            borderRadius: 5,
          }}
        >
          <div>
            <Typography className="theme-font bold">{title}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="theme-font">{subtitle}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  };
  return (
    <div>
      <h3 className="theme-font bold text-align-center">FAQs</h3>
      <Chip
        chipKey="1"
        title={"What is Qunions?"}
        subtitle={`Qunions – Questions n Opinions, is a questions and opinions platform, where there are only opinions to questions – no factual one correct answer. All options are just different opinions to a question/issue. 2 out of all options are winning options. The option/opinion that is selected the most and the option/opinion that is selected the least are winning options. Choose an opinion that you think/analyze would be most popular or most unique in your group or in an open event. There are 3 different Qunions format – Contests, Events and Game. In different formats, there are different implementations of the above stated core concept. Strategize as per the format’s winning objective to win.`}
      />
      <Chip
        chipKey="2"
        title={"What are Qunions Open Contests?"}
        subtitle={`Qunions Open Contests are a format of Qunions. Here, questions are launched about a recently concluded event analyzing that event or on any issue/theme that is purely opinion-based. Users can select an opinion from a set of different opinions and invest in that option within a minimum and maximum range for that contest. For all contests question, there are only opinions – no factual one correct answer! Two out of all options in any/all contests are winning options Choose an opinion that you think/analyze would be the most popular (Majority) or the most unique (Minority) for that question. If your chosen opinion is in Majority or Minority, you will win as per Majority return% or Minority return% on your investment for that contest. Remember, a Trade is only active (open) for a certain duration, so be sure to chip in with your opinion before it is closed. Once the Trade is closed, results are declared on the App.`}
      />
      <Chip
        chipKey="3"
        title={"How are winning returns determined?"}
        subtitle={`Winning return percentages of Majority or Minority winning categories are calculated and displayed live on the App with some lag. The returns are based on the difficulty of identifying any winning category in the population. Lower separability corresponds to higher difficulty and in turn, higher returns. For example, if users have selected a particular option significantly more than the rest of the options then the return on “Majority” winning category will decrease. And if there is not much difference between the most selected option and the 2nd most selected option then the return on “Majority” winning category will increase. Same logic flows for “Minority” winning category returns.`}
      />
      <Chip
        chipKey="4"
        title={"Is Opinion Trading done on real Money on the Platform?"}
        subtitle={`No. Currently, we are offering risk-free trading with virtual currency called rewards money. However, your rewards can be redeemed into real cash prizes. You can make eligible recharges of rewards balance for free unlimited times.`}
      />
      <Chip
        chipKey="5"
        title={"How much “rewards money” can I recharge for free?"}
        subtitle={`Unlimited. If your rewards balance drops down to under 500, you can recharge it up to get to 500 for free. You can keep losing on contests and keep recharging. But if you win, you can avail real cash prizes from your rewards money.`}
      />
      <Chip
        chipKey="6"
        title={"Can I ask/convince other people to Vote the same as I did?"}
        subtitle={`However, it’s not encouraged but it’s not against any rules or it does not constitute violation of any terms and conditions. To keep up with the spirit of the game, we suggest you to keep your opinions private and let the results give you the thrill of your thinking on a topic compared to others.`}
      />
      <Chip
        chipKey="7"
        title={
          "Can I still win if my chosen option is selected the least in a Trade?"
        }
        subtitle={`Yes. That’s one of the winning goals. If your selected option is selected the most or the least, you win. So, choose an option/opinion that you think/analyze would be most popular or most unique in your group or in an open event. If your chosen opinion is in Majority or Minority, you will win as per Majority return% or Minority return% on your investment for that contest.`}
      />
      <Chip
        chipKey="8"
        title={"Does the App ask for any payment method?"}
        subtitle={`No. It’s all completely free. Best part, not even Ads. Just expect to win some real cash and enjoy the experience at Qunions while you are at it.`}
      />
    </div>
  );
}
