import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  ShowButton,
  Show,
  SimpleShowLayout,
  NumberField,
  DateTimeInput,
  DateField,
  SelectInput,
  ReferenceInput,
  ArrayField,
  ImageInput,
  ImageField,
  Filter,
  FileField,
  FileInput,
  Button,
  useRecordContext,
  useNotify,
  ReferenceField,
  useRefresh,
  usePermissions,
  CheckboxGroupInput,
  Edit,
  EditButton,
  BooleanField,
  FunctionField,
} from "react-admin";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import TradeIcon from "@mui/icons-material/ShowChart";
import CopyAllIcon from "@mui/icons-material/ContentCopy";
import { app } from "../constants";
import OptionsFiled from "../components/Options";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import DialogDelete from "../components/DialogDelete";

const __DEV__ = app.__DEV__;
export const getCloudFunctionUrl = () =>
  __DEV__
    ? "https://us-central1-dev-qunions.cloudfunctions.net"
    : "https://us-central1-qunionsapp-f1682.cloudfunctions.net";

const TradeFilter = (props) => {
  const { permissions } = usePermissions();
  const { template_trades, private_trade } = props;
  return (
    <Filter {...props}>
      <TextInput
        label="Search Trade Title"
        source="title"
        fullWidth={true}
        variant="outlined"
        alwaysOn
      />
      {!template_trades && permissions.role == app.ADMIN_ROLES.ADMIN && (
        <SelectInput
          source="status"
          choices={[
            {
              id: "ACTIVE",
              name: "Active",
            },
            {
              id: "ARCHIVED",
              name: "Archived",
            },
            {
              id: "PREVIEW",
              name: "Preview",
              ...(private_trade && { ignore: true }),
            },
          ].filter((fil) => !fil?.ignore)}
          alwaysOn
          fullWidth={true}
          variant="outlined"
        />
      )}
      {!private_trade && !template_trades && (
        <SelectInput
          source="preview_in_feed"
          choices={[
            { id: true, name: "Visible" },
            { id: false, name: "Not-Visible" },
          ]}
          alwaysOn
          fullWidth={true}
          variant="outlined"
          label="Feed Preview"
        />
      )}
      {!private_trade && (
        <ReferenceInput
          source="trade_category"
          reference={
            template_trades ? "template_trade_categories" : "trade_categories"
          }
          fullWidth
          alwaysOn
        >
          <SelectInput
            variant="outlined"
            label="Category"
            optionText={"title"}
          />
        </ReferenceInput>
      )}
      {!private_trade && (
        <ReferenceInput
          sort={{ field: "index", order: "ASC" }}
          source="cricket_week"
          reference={"cricket_weeks"}
          fullWidth
          alwaysOn
        >
          <SelectInput
            variant="outlined"
            label="Cricket Week"
            optionText={"name"}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export const TradeList = (props) => {
  const { private_trade = false, template_trades = false } = props;
  const { permissions } = usePermissions();
  const CopyButton = ({}) => {
    const trade = useRecordContext();
    const navigate = useNavigate();
    return (
      <Button
        endIcon={<CopyAllIcon />}
        onClick={() => navigate("/trades/create", { state: { trade } })}
      >
        Copy Trade
      </Button>
    );
  };
  const MakeActiveButton = ({}) => {
    const [loading, setLoading] = useState(false);
    const record = useRecordContext();
    const refresh = useRefresh();
    const makeActiveTrade = async () => {
      setLoading(true);
      let url =
        getCloudFunctionUrl() + "/makeTradeActive?trade_id=" + record.id;
      Promise.resolve(axios.get(url))
        .then(() => {
          refresh();
        })
        .catch(() => {
          refresh();
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (record.status != "PREVIEW") return "--";
    return (
      <Button onClick={makeActiveTrade}>
        {loading ? <CircularProgress size={20} /> : "Make Active"}
      </Button>
    );
  };
  const ChnageStateOfFeedPreviewButton = ({}) => {
    const record = useRecordContext();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const init = async () => {
      setLoading(true);
      let url =
        getCloudFunctionUrl() +
        "/changeTradePreviewInFeed?trade_id=" +
        record.id;
      Promise.resolve(axios.get(url))
        .then(() => {
          refresh();
        })
        .catch(() => {
          refresh();
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (record?.private_trade || record.status != "ARCHIVED") return "--";
    return (
      <Button onClick={init} disabled={loading}>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>{record?.preview_in_feed ? "Hide from Feed" : "Show in Feed"}</>
        )}
      </Button>
    );
  };
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      filters={<TradeFilter {...props} />}
      filter={{
        private_trade,
        ...(permissions?.role == app.ADMIN_ROLES.EDITOR && {
          status: "PREVIEW",
        }),
      }}
      resource={template_trades ? "template_trades" : "trades"}
      hasCreate={!private_trade}
    >
      <Datagrid
        {...(permissions?.role != app.ADMIN_ROLES.ADMIN && {
          bulkActionButtons: false,
        })}
      >
        <BooleanField source="league" emptyText="--" />
        <TextField source="title" />
        {!template_trades && <TextField source="status" />}
        {!private_trade && (
          <ReferenceField
            source="trade_category"
            reference="trade_categories"
            label="Trade Category"
          >
            <TextField source="title" />
          </ReferenceField>
        )}
        <NumberField source="entries" />
        <NumberField source="majority_odds" label="Majority Odds (%)" />
        <NumberField source="minority_odds" label="Minority Odds (%)" />
        <NumberField
          source="commision"
          emptyText="--"
          label={`Commision(${app.currency})`}
        />
        <TextField source="code" emptyText="--" />
        <DateField
          source="expire_on"
          label="Expire On"
          showTime
          emptyText="--"
        />
        <DateField source="created_at" label="Created" showTime />
        <FunctionField
          label="Archiving D.T"
          render={(record) => (
            <p>
              {record?.db_processing_start && record?.db_processing_end
                ? moment(record.db_processing_end).diff(
                    moment(record.db_processing_start),
                    "seconds"
                  ) + " sec"
                : "--"}
            </p>
          )}
        />
        {!private_trade && !template_trades && (
          <DateField
            source="scheduled_time"
            label="Scheduled On"
            showTime
            emptyText="--"
          />
        )}
        <ShowButton label="Show Trade" />
        {!private_trade &&
          !template_trades &&
          permissions?.role == app.ADMIN_ROLES.ADMIN && (
            <MakeActiveButton label="Make Active" />
          )}
        {!private_trade && !template_trades && (
          <CopyButton {...props} label="Copy Trade" />
        )}
        {!private_trade && !template_trades && (
          <ChnageStateOfFeedPreviewButton label="Feed Status" />
        )}
        {!private_trade &&
          !template_trades &&
          permissions?.role == app.ADMIN_ROLES.ADMIN && <EditButton />}
        {!private_trade &&
          !template_trades &&
          permissions?.role == app.ADMIN_ROLES.ADMIN && (
            <DialogDelete model={"trades"} />
          )}
        <TextField source="created_by" />
        <TextField
          source="copied_from"
          label="Original Author"
          emptyText="--"
        />
      </Datagrid>
    </List>
  );
};

const TradeForm = (props) => {
  const { create, trade, template_trades } = props;
  const [tradeStyle, setTradeStyle] = useState(
    trade && trade?.trade_style ? trade.trade_style : app.TRADE_STYLE.STANDARD
  );
  const [tradeCategory, setTradeCategory] = useState(
    trade && trade?.trade_category ? trade.trade_category : null
  );
  const { permissions } = usePermissions();
  const getOptionsCopy = () => {
    return trade.options.map((op) => {
      if (typeof op == typeof "") return { text: op };
      else {
        let _op = {};
        if (op?.text) _op.text = op.text;
        if (op?.media && op.media?.src) _op.media = { src: op.media.src };
        else _op.media = "";
        if (op?.thumbnail && op.thumbnail?.src)
          _op.thumbnail = { src: op.thumbnail.src };
        else _op.thumbnail = "";
        return _op;
      }
    });
  };
  const getExpireCopy = () => {
    let expire_on = new Date(trade.expire_on);
    let current_date = new Date();
    if (expire_on < current_date) expire_on = current_date;
    return new Date(expire_on.getTime() + 60000 * 1440);
  };
  return (
    <SimpleForm>
      <TextInput
        source="title"
        {...app.inputAttrs}
        {...(__DEV__ && { defaultValue: "Title" })}
        {...(trade && { defaultValue: trade.title })}
      />
      <ReferenceInput
        source="trade_category"
        reference={
          template_trades ? "template_trade_categories" : "trade_categories"
        }
        fullWidth
      >
        <SelectInput
          onChange={(event) => setTradeCategory(event.target.value)}
          {...app.inputAttrs}
          {...(trade && { defaultValue: trade.trade_category })}
          optionText={"title"}
        />
      </ReferenceInput>
      {create && !template_trades && tradeCategory == "cricket" && (
        <ReferenceInput
          source="cricket_week"
          reference={"cricket_weeks"}
          fullWidth
          filter={{ closed: false }}
        >
          <SelectInput
            {...app.inputAttrs}
            optionText={"name"}
            {...(trade &&
              trade?.cricket_week && { defaultValue: trade.cricket_week })}
          />
        </ReferenceInput>
      )}
      {tradeCategory != "cricket" && (
        <DateTimeInput
          source="expire_on"
          {...app.inputAttrs}
          {...(__DEV__ && {
            defaultValue: new Date(new Date().getTime() + 60000 * 10),
            disabled: !create,
          })}
          {...(trade && {
            defaultValue: getExpireCopy(),
          })}
        />
      )}

      <NumberInput
        source="min_amount"
        min={0}
        defaultValue={1}
        label={`Min Amount (${app.currency})`}
        {...app.inputAttrs}
        disabled={!create}
        {...(trade && { defaultValue: trade.min_amount })}
      />
      <NumberInput
        source="max_amount"
        label={`Max Amount (${app.currency})`}
        disabled={!create}
        min={1}
        defaultValue={10}
        {...app.inputAttrs}
        {...(trade && { defaultValue: trade.max_amount })}
      />
      <SelectInput
        source="trade_style"
        choices={Object.keys(app.TRADE_STYLE).map((key) => ({
          id: key,
          name: app.TRADE_STYLE[key],
        }))}
        label="Trade Style"
        defaultValue={tradeStyle}
        {...app.inputAttrs}
        onChange={(event) => {
          setTradeStyle(event.target.value);
        }}
        {...(trade &&
          trade?.trade_style && { defaultValue: trade.trade_style })}
      />
      {create && !template_trades && (
        <SelectInput
          source="league"
          choices={[
            { id: true, name: "League" },
            { id: false, name: "Not-League" },
          ]}
          defaultValue={true}
          {...(trade &&
            trade.hasOwnProperty("league") && { defaultValue: trade.league })}
          {...app.inputAttrs}
        />
      )}
      {create && !template_trades && (
        <DateTimeInput
          source="scheduled_time"
          {...app.inputAttrs}
          validate={[]}
        />
      )}
      <TextInput
        label="Question Label"
        source="question"
        {...app.inputAttrs}
        {...(__DEV__ && { defaultValue: "Question Label" })}
        disabled={!create}
        {...(trade && { defaultValue: trade.question })}
      />
      {create && (
        <FileInput
          style={{ flex: 1 }}
          source="trade_sound"
          label="Trade Sound"
          accept={["audio/*"]}
          maxSize={10000000}
          defaultValue={""}
          {...(trade &&
            trade?.trade_sound &&
            trade.trade_sound?.src && {
              defaultValue: trade.trade_sound,
            })}
        >
          <FileField source="src" title="View Media" target="_blank" />
        </FileInput>
      )}
      {create && (
        <FileInput
          style={{ flex: 1 }}
          source="question_image"
          label="Question Media"
          multiple={false}
          accept={["image/*", "video/mp4"]}
          maxSize={20000000}
          defaultValue={""}
          {...(trade &&
            trade?.question_image &&
            trade.question_image?.src && {
              defaultValue: trade.question_image,
            })}
        >
          <FileField source="src" title="View Media" target="_blank" />
        </FileInput>
      )}
      {create && (
        <ArrayInput
          source="options"
          defaultValue={[
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
          ]}
          {...(trade && {
            defaultValue: getOptionsCopy(),
          })}
        >
          <SimpleFormIterator inline fullWidth>
            <TextInput
              source="text"
              helperText={false}
              {...app.inputAttrs}
              label="Option Text"
            />
            {tradeStyle != app.TRADE_STYLE.STANDARD && (
              <FileInput
                style={{ flex: 1 }}
                source="media"
                label="Option Media"
                multiple={false}
                accept={["image/*", "video/mp4"]}
                maxSize={100000000}
              >
                <FileField source="src" title="View Media" target="_blank" />
              </FileInput>
            )}
            {tradeStyle != app.TRADE_STYLE.STANDARD && (
              <ImageInput
                style={{ flex: 1 }}
                source="thumbnail"
                label="Thumbnail (if required)"
                multiple={false}
                accept={["image/*"]}
                maxSize={5000000}
              >
                <ImageField source="src" title="Thumbnail" />
              </ImageInput>
            )}
          </SimpleFormIterator>
        </ArrayInput>
      )}
      {create && !template_trades && (
        <ArrayInput
          source="bot_responses"
          label="Bot Responses"
          {...(!app.__DEV__ && {
            defaultValue: [
              { option: "A" },
              { option: "B" },
              { option: "C" },
              { option: "D" },
            ],
          })}
          {...(trade &&
            (trade?.bot_responses || []).length > 0 &&
            (permissions?.role == app.ADMIN_ROLES.ADMIN ||
              (permissions?.email == trade?.created_by &&
                ["", permissions?.email, undefined].includes(
                  trade?.copied_from
                ))) && {
              defaultValue: trade.bot_responses,
            })}
        >
          <SimpleFormIterator inline fullWidth>
            <TextInput
              source="option"
              helperText={"Option [A,B,C,D]"}
              {...app.inputAttrs}
              fullWidth={false}
            />
            <NumberInput
              source="number_of_responses"
              helperText={"Any number to load dummy bot response."}
              {...app.inputAttrs}
              fullWidth={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
      )}
      {create && !template_trades && (
        <NumberInput
          source="bot_amount_per_response"
          label={"Bot Amount"}
          helperText={"Bot Amount Per Response"}
          {...app.inputAttrs}
          defaultValue={10}
          {...(trade && { defaultValue: trade?.bot_amount_per_response || 10 })}
        />
      )}
      {create && !template_trades && (
        <CheckboxGroupInput
          source="send_notifications"
          choices={[
            { id: "new", name: "New Trades" },
            { id: "deleted", name: "Deleted Trades" },
            { id: "running_out", name: "Running Time Trades" },
          ]}
        />
      )}
      {trade && trade?.created_by && (
        <TextInput
          sx={{
            display: "none",
          }}
          source="copied_from"
          defaultValue={trade?.copied_from || trade.created_by || ""}
          type="hidden"
          label=""
        />
      )}
      {trade && trade?.question_image && trade?.question_image.thumbnail && (
        <TextInput
          sx={{
            display: "none",
          }}
          source="question_image_thumbnail"
          defaultValue={trade?.question_image.thumbnail.src}
          type="hidden"
          label=""
        />
      )}
    </SimpleForm>
  );
};
export const TradeCreate = (props) => {
  const { template_trades } = props;
  const { state } = useLocation();
  const notify = useNotify();
  const storage = getStorage();
  const getPromiseFromEvent = (item, event, cb) => {
    return new Promise((resolve) => {
      const listener = async () => {
        await cb();
        item.removeEventListener(event, listener);
        resolve();
      };
      item.addEventListener(event, listener);
    });
  };
  const uploadFile = async (canvas, cb) => {
    return new Promise(async (resolve, reject) => {
      canvas.toBlob(async (blob) => {
        let fileName = uuidv4() + ".jpeg";
        let storageRef = ref(storage, "trades_auto_thumbnails/" + fileName);
        const file = new File([blob], fileName, {
          type: blob.type,
        });
        await uploadBytes(storageRef, file); // Wait for the upload to finish
        let th_url = await getDownloadURL(storageRef);
        cb(th_url, fileName);
        resolve();
      }, "image/jpeg");
    });
  };
  const makeAndUploadThumbnail = async (videoUrl, cb) => {
    let video = document.createElement("video");
    video.src = videoUrl;
    await getPromiseFromEvent(video, "loadeddata", async () => {
      video.currentTime = video.duration;
      return await getPromiseFromEvent(video, "seeked", async () => {
        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        let context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        await uploadFile(canvas, cb);
      });
    });
  };
  const transformData = async (_data) => {
    let data = { ..._data };
    if (data?.scheduled_time) {
      if (new Date().getTime() > data.scheduled_time.getTime()) {
        notify("Scheduled time must be greater than current time.");
        return Promise.reject();
      }
    } else delete data.scheduled_time;

    if (!data?.trade_sound) delete data?.trade_sound;

    if (data.trade_category == "cricket") delete data?.expire_on;
    if (data?.expire_on && new Date().getTime() > data.expire_on.getTime()) {
      notify("Expire time must be greater than current time.");
      return Promise.reject();
    }
    if (!data.send_notifications) data.send_notifications = [];
    const options = data.options || [];
    const option_length = options.length;
    if (option_length != 4) {
      notify("Options length must be 4.");
      return Promise.reject();
    }
    for (let i = 0; i < option_length; i++) {
      let option = options[i];
      if (option?.media && !option?.thumbnail) {
        if (
          ["mp4"].some(
            (ext) =>
              option.media?.rawFile &&
              option.media.rawFile?.type &&
              option.media.rawFile.type.includes(ext)
          )
        ) {
          await makeAndUploadThumbnail(option.media.src, (th_url, fileName) => {
            data.options[i].thumbnail = {
              src: th_url,
              title: fileName,
            };
          });
        } else if (!option?.thumbnail) delete option.thumbnail;
      }
    }

    // Create Thumbnail and save for question_image if exists
    if (data?.question_image) {
      let q_image = data?.question_image;
      if (
        q_image?.rawFile &&
        q_image.rawFile?.type &&
        q_image.rawFile.type.includes("mp4")
      ) {
        await makeAndUploadThumbnail(q_image.src, (th_url, fileName) => {
          data.question_image.thumbnail = {
            src: th_url,
            title: fileName,
          };
        });
      }
    }

    // Add thumbnail to coped question image video
    if (data?.question_image_thumbnail) {
      let q_image = data?.question_image;
      if (q_image.src.includes("mp4")) {
        data.question_image.thumbnail = {
          src: data?.question_image_thumbnail,
        };
      }
      delete data?.question_image_thumbnail;
    }
    return data;
  };
  return (
    <Create
      transform={transformData}
      resource={template_trades ? "template_trades" : "trades"}
    >
      <TradeForm create {...props} trade={state?.trade} />
    </Create>
  );
};
const EditTrade = () => {
  const { permissions } = usePermissions();
  return (
    <Edit
      transform={(_data) => {
        let data = { ..._data };
        if (!data.trade_sound) data.trade_sound = "";
        if (!data.send_notifications) data.send_notifications = [];
        return data;
      }}
    >
      <SimpleForm>
        {permissions?.role == app.ADMIN_ROLES.ADMIN && (
          <TextInput source="title" {...app.inputAttrs} />
        )}
        {permissions?.role == app.ADMIN_ROLES.ADMIN && (
          <TextInput
            label="Question Label"
            source="question"
            {...app.inputAttrs}
          />
        )}
        <CheckboxGroupInput
          source="send_notifications"
          choices={[
            { id: "new", name: "New Trades" },
            { id: "deleted", name: "Deleted Trades" },
            { id: "running_out", name: "Running Time Trades" },
          ]}
        />
        <SelectInput
          source="league"
          choices={[
            { id: true, name: "League" },
            { id: false, name: "Not-League" },
          ]}
          {...app.inputAttrs}
        />
        <FileInput
          source="trade_sound"
          label="Trade Sound"
          accept={["audio/*"]}
          maxSize={10000000}
        >
          <FileField source="src" title="View Media" target="_blank" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
export const TradesShow = () => {
  const { permissions } = usePermissions();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="status" />
        <NumberField source="entries" />
        <DateField source="expire_on" label="Expire On" showTime />
        <NumberField source="majority_odds" label="Majority Odds (%)" />
        <NumberField source="minority_odds" label="Minority Odds (%)" />
        <NumberField
          source="min_amount"
          label={`Min Amount (${app.currency})`}
        />
        <NumberField
          source="max_amount"
          label={`Max Amount (${app.currency})`}
        />
        <TextField source="question" />
        <FunctionField
          label="Question Media"
          render={(record) => {
            if (!record?.question_image) return "--";
            else if (record.question_image?.video)
              return (
                <video controls width={300}>
                  <source src={record.question_image.src} type="video/mp4" />
                </video>
              );
            else
              return (
                <ImageField
                  source="question_image.src"
                  label="Question Image/Video"
                  emptyText="--"
                />
              );
          }}
        />
        <OptionsFiled source="options" />
        {permissions.role == app.ADMIN_ROLES.ADMIN && (
          <ArrayField
            source="all_options_price"
            label="Distribution Stats"
            sortBy="option"
          >
            <Datagrid bulkActionButtons={false} empty="--">
              <TextField source="option" />
              <TextField source="type" />
              <NumberField
                source="distribution"
                options={{ maximumFractionDigits: 2 }}
              />
              <NumberField source="total_real_users" label="Real Users" />
              <NumberField source="total_bots" label="Total Bots" />
              <NumberField
                source="total_amount"
                label="Amount Invested"
                options={{ maximumFractionDigits: 2 }}
              />
              <NumberField
                source="total_return"
                label="Amount Return"
                options={{ maximumFractionDigits: 2 }}
                f
              />
            </Datagrid>
          </ArrayField>
        )}
      </SimpleShowLayout>
    </Show>
  );
};
export default {
  list: TradeList,
  create: TradeCreate,
  edit: EditTrade,
  name: "trades",
  icon: TradeIcon,
  label: "Trades",
  show: TradesShow,
};
