import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SimpleShowLayout,
  Show,
} from "react-admin";
import UserIcon from "@mui/icons-material/PeopleOutline";

const UserList = () => (
  <List>
    <Datagrid rowClick={"show"} bulkActionButtons={false}>
      <TextField source="user_id" />
      <EmailField source="email" />
    </Datagrid>
  </List>
);
const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <EmailField source="user_id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
export default {
  list: UserList,
  show: UserShow,
  name: "users",
  icon: UserIcon,
  label: "Users",
};
