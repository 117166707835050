// in src/Menu.js
import * as React from "react";
import { Menu, MenuItemLink, usePermissions } from "react-admin";

import AppsIcon from "@mui/icons-material/Apps";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import TradeIcon from "@mui/icons-material/PriceChange";
import SubMenu from "./SubMenu";

import questions from "../questions";
import themes from "../themes";
import games from "../games";
import trades from "../trades";
import private_trades from "../private_trades";
import template_trades from "../template_trades";
import template_trade_categories from "../template_trade_categories";
import trade_categories from "../trade_categories";
import videos from "../videos";
import signup_gift_cards from "../signup_gift_cards";
import collapse_videos from "../collapse_videos";
import trade_league_banners from "../trade_league_banners";
import league_winner_prices from "../league_winner_prices";
import cricket_winner_prices from "../cricket_winner_prices";
import weekly_tracks from "../weekly_tracks";
import cricket_weekly_tracks from "../cricket_weekly_tracks";
import media from "../media";
import folders from "../folders";
import users from "../users";
import gift_cards from "../gift_cards";
import admins from "../admins";
import cricket_weeks from "../cricket_weeks";
import ambassadors from "../ambassadors";
import user_responses from "../user_responses";
import gift_card_requests from "../gift_card_requests";
import bugs from "../bugs";
import library from "../library";
import library_categories from "../library_categories";
import Divider from "@mui/material/Divider";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { app } from "../constants";

const CustomMenu = (props) => {
  const { permissions } = usePermissions();
  const [state, setState] = React.useState({
    trades: true,
    private_trades: true,
    template_trades: true,
    users: true,
    games: true,
    media: true,
    library: true,
    admin: true,
    public: true,
    gifts: true,
    week: true,
  });
  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  if (permissions?.role == app.ADMIN_ROLES.YOUTUBER)
    return (
      <Menu {...props}>
        <MenuItemLink
          to={cricket_weeks.name}
          primaryText={cricket_weeks.label}
          leftIcon={<cricket_weeks.icon />}
        />
      </Menu>
    );
  return (
    <Menu {...props}>
      {permissions?.role == app.ADMIN_ROLES.ADMIN && (
        <>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("users")}
            isOpen={state.users}
            name="Manage Users"
            icon={<users.icon />}
          >
            <MenuItemLink
              to={users.name}
              primaryText={users.label}
              leftIcon={<users.icon />}
            />
            <MenuItemLink
              to={admins.name}
              primaryText={admins.label}
              leftIcon={<admins.icon />}
            />
            <MenuItemLink
              to={ambassadors.name}
              primaryText={ambassadors.label}
              leftIcon={<ambassadors.icon />}
            />
            <MenuItemLink
              to={user_responses.name}
              primaryText={user_responses.label}
              leftIcon={<user_responses.icon />}
            />
            <MenuItemLink
              to={league_winner_prices.name}
              primaryText={league_winner_prices.label}
              leftIcon={<league_winner_prices.icon />}
            />
          </SubMenu>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("week")}
            isOpen={state.week}
            name="Manage Contest Weeks"
            icon={<cricket_weeks.icon />}
          >
            <MenuItemLink
              to={cricket_weeks.name}
              primaryText={cricket_weeks.label}
              leftIcon={<cricket_weeks.icon />}
            />
            <MenuItemLink
              to={cricket_winner_prices.name}
              primaryText={cricket_winner_prices.label}
              leftIcon={<cricket_winner_prices.icon />}
            />
            <MenuItemLink
              to={cricket_weekly_tracks.name}
              primaryText={cricket_weekly_tracks.label}
              leftIcon={<cricket_weekly_tracks.icon />}
            />
            <MenuItemLink
              to={weekly_tracks.name}
              primaryText={weekly_tracks.label}
              leftIcon={<weekly_tracks.icon />}
            />
          </SubMenu>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("gifts")}
            isOpen={state.gifts}
            name="Manage Gift Cards"
            icon={<gift_cards.icon />}
          >
            <MenuItemLink
              to={signup_gift_cards.name}
              primaryText={signup_gift_cards.label}
              leftIcon={<signup_gift_cards.icon />}
            />
            <MenuItemLink
              to={gift_cards.name}
              primaryText={gift_cards.label}
              leftIcon={<gift_cards.icon />}
            />
            <MenuItemLink
              to={"bulk_gift_cards"}
              primaryText={"Bulk Gift Cards"}
              leftIcon={<gift_card_requests.icon />}
            />
          </SubMenu>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("games")}
            isOpen={state.games}
            name="Manage Games"
            icon={<games.icon />}
          >
            <MenuItemLink
              to={games.name}
              primaryText={games.label}
              leftIcon={<games.icon />}
            />
            <MenuItemLink
              to={themes.name}
              primaryText={themes.label}
              leftIcon={<themes.icon />}
            />
            <MenuItemLink
              to={questions.name}
              primaryText={questions.label}
              leftIcon={<questions.icon />}
            />
            <MenuItemLink
              to={"bulk_questions"}
              primaryText={"Bulk Questions"}
              leftIcon={<AppsIcon />}
            />
          </SubMenu>
          <Divider />
        </>
      )}
      {permissions?.role == app.ADMIN_ROLES.EDITOR && (
        <>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("games")}
            isOpen={state.games}
            name="Manage Games"
            icon={<games.icon />}
          >
            <MenuItemLink
              to={questions.name}
              primaryText={questions.label}
              leftIcon={<questions.icon />}
            />
          </SubMenu>
        </>
      )}
      <SubMenu
        handleToggle={() => handleToggle("admin")}
        isOpen={state.admin}
        name="Admin"
        icon={<SupervisorAccountIcon />}
      >
        <SubMenu
          handleToggle={() => handleToggle("trades")}
          isOpen={state.trades}
          name="Admin Trades"
          icon={<trades.icon />}
          subMenu
        >
          <MenuItemLink
            to={trades.name}
            primaryText={trades.label}
            leftIcon={<trades.icon />}
          />
          {permissions?.role == app.ADMIN_ROLES.ADMIN && (
            <MenuItemLink
              to={trade_categories.name}
              primaryText={trade_categories.label}
              leftIcon={<trade_categories.icon />}
            />
          )}
          <MenuItemLink
            to={"bulk_trades"}
            primaryText={"Bulk Trades"}
            leftIcon={<TradeIcon />}
          />
          {permissions?.role == app.ADMIN_ROLES.ADMIN && (
            <MenuItemLink
              to={collapse_videos.name}
              primaryText={collapse_videos.label}
              leftIcon={<collapse_videos.icon />}
            />
          )}
        </SubMenu>
        {permissions?.role == app.ADMIN_ROLES.ADMIN && (
          <>
            <SubMenu
              handleToggle={() => handleToggle("media")}
              isOpen={state.media}
              name="Admin Media"
              icon={<media.icon />}
              subMenu
            >
              <MenuItemLink
                to={media.name}
                primaryText={media.label}
                leftIcon={<media.icon />}
              />
              <MenuItemLink
                to={folders.name}
                primaryText={folders.label}
                leftIcon={<folders.icon />}
              />
            </SubMenu>
          </>
        )}
        {permissions?.role == app.ADMIN_ROLES.ADMIN && (
          <MenuItemLink
            to={trade_league_banners.name}
            primaryText={trade_league_banners.label}
            leftIcon={<trade_league_banners.icon />}
          />
        )}
      </SubMenu>
      {permissions?.role == app.ADMIN_ROLES.ADMIN && (
        <>
          <Divider />
          <SubMenu
            handleToggle={() => handleToggle("public")}
            isOpen={state.public}
            name="Public"
            icon={<PeopleAltIcon />}
          >
            <SubMenu
              handleToggle={() => handleToggle("template_trades")}
              isOpen={state.template_trades}
              name="Template Trades"
              icon={<trades.icon />}
              subMenu
            >
              <MenuItemLink
                to={template_trades.name}
                primaryText={template_trades.label}
                leftIcon={<template_trades.icon />}
              />
              <MenuItemLink
                to={template_trade_categories.name}
                primaryText={template_trade_categories.label}
                leftIcon={<template_trade_categories.icon />}
              />
              <MenuItemLink
                to={"bulk_template_trades"}
                primaryText={"Bulk Trades"}
                leftIcon={<TradeIcon />}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("private_trades")}
              isOpen={state.private_trades}
              name="Private Trades"
              icon={<trades.icon />}
              subMenu
            >
              <MenuItemLink
                to={private_trades.name}
                primaryText={private_trades.label}
                leftIcon={<private_trades.icon />}
              />
            </SubMenu>
            <SubMenu
              handleToggle={() => handleToggle("library")}
              isOpen={state.library}
              name="Template Library"
              icon={<library.icon />}
              subMenu
            >
              <MenuItemLink
                to={library.name}
                primaryText={library.label}
                leftIcon={<library.icon />}
              />
              <MenuItemLink
                to={library_categories.name}
                primaryText={library_categories.label}
                leftIcon={<library_categories.icon />}
              />
            </SubMenu>
          </SubMenu>
          <Divider />
          <MenuItemLink
            to={videos.name}
            primaryText={videos.label}
            leftIcon={<videos.icon />}
          />
          <MenuItemLink
            to={collapse_videos.name}
            primaryText={collapse_videos.label}
            leftIcon={<collapse_videos.icon />}
          />
          <Divider />
          <MenuItemLink
            to={bugs.name}
            primaryText={bugs.label}
            leftIcon={<bugs.icon />}
          />
          <Divider />
          <MenuItemLink
            to={"send_notifications"}
            primaryText={"Send Notifications"}
            leftIcon={<NotificationAddIcon />}
          />
        </>
      )}
    </Menu>
  );
};
export default CustomMenu;
