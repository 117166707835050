import React from "react";
import { useRecordContext } from "react-admin";
import { List, ListItem } from "@mui/material";

const OptionsFiled = ({ source, counter }) => {
  const record = useRecordContext();
  return (
    <>
      <List
        style={{
          padding: 0,
          margin: 0,
        }}
        className="css-e784if-MuiTypography-root"
      >
        {record[source].map((item, index) => (
          <ListItem key={index}>
            {counter ? `${index + 1}. ` : "●"} {item}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default OptionsFiled;
