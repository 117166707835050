import React from "react";
import { useRecordContext } from "react-admin";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const OptionsFiled = ({ source }) => {
  const record = useRecordContext();
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Media</TableCell>
              <TableCell>Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record[source].map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{
                    width: 120,
                  }}
                >
                  <center>
                    {row?.media && row.media?.src ? (
                      ["mp4"].some((ext) => row.media.src.includes(ext)) ? (
                        <video
                          controls
                          style={{
                            borderRadius: 10,
                          }}
                          width="200"
                        >
                          <source src={row.media.src} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={row.media.src}
                          style={{
                            height: 120,
                            borderRadius: 10,
                          }}
                        />
                      )
                    ) : (
                      "--"
                    )}
                  </center>
                </TableCell>
                <TableCell>{row.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OptionsFiled;
