import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  Edit,
  required,
  EditButton,
  ShowButton,
} from "react-admin";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import { app } from "../constants";
import DialogDelete from "../components/DialogDelete";

const AdminList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <EmailField source="email" />
      <TextField
        source="role"
        sx={{
          textTransform: "capitalize",
        }}
      />
      <EditButton />
      <ShowButton />
      <DialogDelete model={"admins"} />
    </Datagrid>
  </List>
);
const AdminForm = ({ edit }) => {
  return (
    <SimpleForm>
      {!edit && <TextInput source="name" {...app.inputAttrs} label="Name" />}
      {!edit && (
        <TextInput
          source="email"
          {...app.inputAttrs}
          label="Email"
          type="email"
          validate={[required()]}
        />
      )}
      {!edit && (
        <TextInput
          source="password"
          {...app.inputAttrs}
          label="Password"
          type="password"
        />
      )}
      <SelectInput
        source="role"
        {...app.inputAttrs}
        choices={[
          { id: app.ADMIN_ROLES.ADMIN, name: "Admin" },
          { id: app.ADMIN_ROLES.EDITOR, name: "Editor" },
          { id: app.ADMIN_ROLES.YOUTUBER, name: "Youtuber" },
        ]}
      />
    </SimpleForm>
  );
};
const AdminCreate = () => {
  return (
    <Create>
      <AdminForm />
    </Create>
  );
};
const AdminEdit = () => {
  return (
    <Edit>
      <AdminForm edit />
    </Edit>
  );
};
export default {
  list: AdminList,
  create: AdminCreate,
  edit: AdminEdit,
  name: "admins",
  icon: AdminIcon,
  label: "Admins",
};
