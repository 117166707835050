import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  useRecordContext,
  useRefresh,
  Button,
  useDataProvider,
} from "react-admin";
import GiftCardRequestIcon from "@mui/icons-material/Money";
import { CircularProgress } from "@mui/material";

const GiftCardRequestList = () => {
  const RequestStatus = ({}) => {
    const [loading, setLoading] = useState(false);
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const updateStatus = async (status) => {
      setLoading(true);
      await dataProvider.update("gift_card_requests", {
        id: record.id,
        data: { status },
        previousData: record,
      });
      refresh();
      setLoading(false);
    };
    const status = record?.status;
    if (!status)
      return (
        <div>
          <Button onClick={() => updateStatus("accepted")}>
            {loading ? <CircularProgress size={20} /> : "Accpet"}
          </Button>
          <Button onClick={() => updateStatus("rejected")}>
            {loading ? <CircularProgress size={20} /> : "Reject"}
          </Button>
        </div>
      );
    if (status == "accepted")
      return (
        <Button onClick={() => updateStatus("delievered")}>
          {loading ? <CircularProgress size={20} /> : "Deliever"}
        </Button>
      );
    if (status == "delievered") return "Completed";
    if (status == "rejected") return "Rejected";
  };
  return (
    <List sort={{ field: "created_at", order: "DESC" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="code" />
        <NumberField source="redeem_price" label="Redeem Price" />
        <RequestStatus />
      </Datagrid>
    </List>
  );
};

export default {
  list: GiftCardRequestList,
  name: "gift_card_requests",
  icon: GiftCardRequestIcon,
  label: "Gift Card Requests",
};
