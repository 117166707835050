import * as React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";

import { Route, Routes, BrowserRouter } from "react-router-dom";

import layout from "./layout";

import { app, theme } from "./constants";
import { QueryClient } from "react-query";

import themes from "./themes";
import questions from "./questions";
import games from "./games";
import signup_gift_cards from "./signup_gift_cards";
import trades from "./trades";
import private_trades from "./private_trades";
import template_trades from "./template_trades";
import trade_league_banners from "./trade_league_banners";
import template_trade_categories from "./template_trade_categories";
import bugs from "./bugs";
import library from "./library";
import library_categories from "./library_categories";
import cricket_weeks from "./cricket_weeks";
import trade_categories from "./trade_categories";
import videos from "./videos";
import collapse_videos from "./collapse_videos";
import league_winner_prices from "./league_winner_prices";
import cricket_winner_prices from "./cricket_winner_prices";
import weekly_tracks from "./weekly_tracks";
import cricket_weekly_tracks from "./cricket_weekly_tracks";
import media from "./media";
import users from "./users";
import gift_cards from "./gift_cards";
import admins from "./admins";
import ambassadors from "./ambassadors";
import user_responses from "./user_responses";
import folders from "./folders";

import BulkQuestions from "./extra/BulkQuestions";
import BulkTrade from "./extra/BulkTrade";
import Play from "./extra/Play";
import CustomNotifications from "./extra/CustomNotifications";
import BulkGiftCards from "./extra/BulkGiftCards";

const options = {
  renameMetaFields: {
    created_at: "created_at",
    created_by: "created_by",
    updated_at: "updated_at",
    updated_by: "updated_by",
  },
  persistence: "session",
  lazyLoading: {
    enabled: false,
  },
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
let configs = app.__DEV__ ? app.devFirebaseConfig : app.firebaseConfig;
const authProvider = FirebaseAuthProvider(configs);
const dataProvider = FirebaseDataProvider(configs, options);
if (!app.__DEV__) {
  dataProvider.delete = async (resource, params) => {
    const user = await authProvider.getPermissions();
    if (user?.role != "admin")
      return Promise.reject("You have no permission to delete.");
    return Promise.resolve();
  };
  dataProvider.deleteMany = async (resource, params) => {
    const user = await authProvider.getPermissions();
    if (user?.role != "admin")
      return Promise.reject("You have no permission to delete.");
    return Promise.resolve();
  };
}
const AdminPanel = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      theme={theme}
      layout={layout}
      authProvider={authProvider}
      queryClient={queryClient}
    >
      {(permissions) => {
        if (!permissions) window.location.replace("/login");
        else if (!Object.values(app.ADMIN_ROLES).includes(permissions?.role))
          window.location.replace("https://qunions.com/");
        return permissions?.role ? (
          permissions?.role == app.ADMIN_ROLES.YOUTUBER ? (
            <>
              <Resource {...cricket_weeks} />
            </>
          ) : (
            <>
              <Resource {...trades} />
              <Resource {...questions} />
              {permissions?.role == app.ADMIN_ROLES.ADMIN && (
                <>
                  <Resource {...admins} />
                  <Resource {...cricket_weeks} />
                  <Resource {...media} />
                  <Resource {...folders} />
                  <Resource {...signup_gift_cards} />
                  <Resource {...trade_categories} />
                  <Resource {...collapse_videos} />
                  <Resource {...template_trades} />
                  <Resource {...private_trades} />
                  <Resource {...themes} />
                  <Resource {...games} />
                  <Resource {...user_responses} />
                  <Resource {...template_trade_categories} />
                  <Resource {...trade_league_banners} />
                  <Resource {...videos} />
                  <Resource {...league_winner_prices} />
                  <Resource {...cricket_winner_prices} />
                  <Resource {...weekly_tracks} />
                  <Resource {...cricket_weekly_tracks} />
                  <Resource {...users} />
                  <Resource {...gift_cards} />
                  <Resource {...ambassadors} />
                  <Resource {...bugs} />
                  <Resource {...library} />
                  <Resource {...library_categories} />
                </>
              )}
              <CustomRoutes>
                <Route path="/bulk_trades" element={<BulkTrade />} />
                {permissions?.role == app.ADMIN_ROLES.ADMIN && (
                  <>
                    <Route path="/bulk_questions" element={<BulkQuestions />} />
                    <Route
                      path="/bulk_gift_cards"
                      element={<BulkGiftCards />}
                    />
                    <Route
                      path="/bulk_template_trades"
                      element={<BulkTrade template_trades />}
                    />
                    <Route
                      path="/send_notifications"
                      element={<CustomNotifications />}
                    />
                  </>
                )}
              </CustomRoutes>
            </>
          )
        ) : (
          <></>
        );
      }}
    </Admin>
  );
};

export default () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"admin"} element={<AdminPanel />} />
        <Route path={"how-to-play"} element={<Play />} />
        <Route path={"play"} element={<Play />} />
        <Route path={"*"} element={<AdminPanel />} />
      </Routes>
    </BrowserRouter>
  );
};
