import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  Edit,
  EditButton,
  ShowButton,
  Show,
  SimpleShowLayout,
  FunctionField,
  ReferenceArrayField,
  NumberField,
} from "react-admin";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AmbassadorIcon from "@mui/icons-material/WorkspacePremium";
import { app } from "../constants";
import CountFiled from "../components/Count";
import FileCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";

const copyToClipboard = (data) => {
  const tempInput = document.createElement("input");
  tempInput.value = data;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};

const transformData = (data) => {
  data.referral_code = data.referral_code.toLocaleUpperCase();
  data.id = data.referral_code;
  return data;
};
const AmbassadorList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="referral_code" label="Referral Code" />

      <FunctionField
        render={(record) => (
          <div>
            <div
              style={{
                display: "inline",
              }}
            >
              <Button
                onClick={() =>
                  copyToClipboard(
                    `https://qunions.com/download?referral=${record.referral_code}`
                  )
                }
              >
                <FileCopyIcon />
                <div
                  style={{
                    marginLeft: 5,
                  }}
                >
                  Copy Referral Url
                </div>
              </Button>
            </div>
          </div>
        )}
        label=""
      />
      <NumberField
        source="referral_link_opened"
        label="Referral Link Opened"
        emptyText="0"
      />
      <CountFiled source="users" label="Total Users" />
      <ShowButton label="Complete Stats" />
      <EditButton />
    </Datagrid>
  </List>
);
const AmbassadorForm = ({ edit }) => {
  return (
    <SimpleForm>
      <TextInput source="name" {...app.inputAttrs} label="Name" />
      {!edit && (
        <TextInput
          source="referral_code"
          {...app.inputAttrs}
          label="Referral Code"
        />
      )}
    </SimpleForm>
  );
};
const AmbassadorCreate = () => {
  return (
    <Create transform={transformData}>
      <AmbassadorForm />
    </Create>
  );
};
const AmbassadorEdit = () => {
  return (
    <Edit transform={transformData}>
      <AmbassadorForm edit />
    </Edit>
  );
};
const AmbassadorShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="referral_code" />
      <CountFiled source="users" label="Total Users" />
      <ReferenceArrayField label="Users" reference="users" source="users">
        <Datagrid bulkActionButtons={null}>
          <TextField source="id" label="User Id" />
          <TextField source="email" />
        </Datagrid>
      </ReferenceArrayField>
      <FunctionField
        label="User Trades"
        render={(record) =>
          record.stats ? (
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>User Id</TableCell>
                  <TableCell>Total Trades</TableCell>
                  <TableCell>Gift Card Sent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(Object.keys(record.stats) || []).map((key, index) => (
                  <>
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{key}</TableCell>
                      <TableCell>{record.stats[key].total_trades}</TableCell>
                      <TableCell>
                        {record.stats[key].gift_card_sent ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          ) : (
            <>--</>
          )
        }
      />
    </SimpleShowLayout>
  </Show>
);
export default {
  list: AmbassadorList,
  create: AmbassadorCreate,
  show: AmbassadorShow,
  edit: AmbassadorEdit,
  name: "ambassadors",
  icon: AmbassadorIcon,
  label: "Ambassadors",
};
