import { defaultTheme } from "react-admin";
import { required } from "react-admin";
export const app = {
  colorOne: "#13A4AD",
  colorTwo: "#297EE9",
  firebaseConfig: {
    apiKey: "AIzaSyC-88bnPo_rgDkXmoOng-Pq67w1S7ED-wg",
    authDomain: "qunionsapp-f1682.firebaseapp.com",
    databaseURL: "https://qunionsapp-f1682-default-rtdb.firebaseio.com",
    projectId: "qunionsapp-f1682",
    storageBucket: "qunionsapp-f1682.appspot.com",
    messagingSenderId: "506270410265",
    appId: "1:506270410265:web:ee2c7a0ae802c0800f6c88",
  },
  devFirebaseConfig: {
    apiKey: "AIzaSyAiLyfpmXw90hn_jPcUFlcerCF4lEaTNKs",
    authDomain: "dev-qunions.firebaseapp.com",
    databaseURL: "https://dev-qunions-default-rtdb.firebaseio.com",
    projectId: "dev-qunions",
    storageBucket: "dev-qunions.appspot.com",
    messagingSenderId: "188280089683",
    appId: "1:188280089683:web:110183dfc482245b502aa6",
  },
  inputAttrs: { fullWidth: true, variant: "outlined", validate: [required()] },
  sort_by: [
    { id: "most_popular", name: "Most Popular" },
    { id: "featured", name: "Featured" },
    { id: "least_popular", name: "Least popular" },
  ],
  currency: "₹",
  option_mappings: { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E" },
  option_mappings_reverse: { A: 0, B: 1, C: 2, D: 3, E: 4 },
  __DEV__: ["localhost", "dev-qunions.web.app"].includes(
    window.location.hostname
  ),
  TRADE_STYLE: {
    SPLIT: "SPLIT",
    NOSPLIT: "NOSPLIT",
    STANDARD: "STANDARD",
  },
  ADMIN_ROLES: {
    ADMIN: "admin",
    EDITOR: "editor",
    YOUTUBER: "youtuber",
  },
  TIMEZONE: "Asia/Kolkata",
};
export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: app.colorOne,
    },
    secondary: {
      main: app.colorOne,
      light: app.colorTwo,
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
    type: "light",
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "5px solid red",
      },
      active: {
        borderLeft: `5px solid ${app.colorOne}`,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: app.colorOne,
        boxShadow: "none",
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        marginRight: 10,
        borderBottom: `10px solid ${app.colorOne}`,
        paddingRight: 10,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        border: "none",
      },
    },
  },
  props: {
    MuiButtonBase: {
      //disableRipple: true,
    },
  },
};
