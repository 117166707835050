import * as React from "react";

import { Title } from "react-admin";
import { useNotify } from "react-admin";
import {
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  TextField as MaterialTextField,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { NotificationAdd } from "@mui/icons-material";
import { getCloudFunctionUrl } from "../trades";
import axios from "axios";

const Password = () => {
  const notify = useNotify();
  const error = (string, type = "error") => {
    notify(string, type);
  };
  const [checked, setChecked] = React.useState(false);
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: "",
    user_ids: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const sendNotifications = (e) => {
    e.preventDefault();
    const { title, body, data = {}, user_ids } = values;
    if (title && body) {
      let url =
        getCloudFunctionUrl() +
        `/sendNotifications?in_app=${
          checked ? 1 : 0
        }&user_ids=${user_ids}&title=${title}&body=${body}`;
      if (Object.keys(data).length > 0) {
        url += `&data=${JSON.stringify(data)}`;
      }
      Promise.resolve(axios.get(url))
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
      setValues({
        title: "",
        body: "",
        data: "",
        user_ids: "",
      });
      setChecked(false);
      error("Notifications sent successfully", "success");
    } else {
      error("Please provide all values to continue.");
    }
  };
  return (
    <Card
      style={{
        marginTop: 20,
      }}
    >
      <Title title={"Send Custom Notifications"} />
      <CardContent>
        <form onSubmit={sendNotifications}>
          <FormControl fullWidth>
            <Grid container>
              <Grid item xs={12}>
                <MaterialTextField
                  autoFocus
                  label="Title"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.title}
                  required
                  onChange={handleChange("title")}
                  style={{
                    marginBottom: 20,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialTextField
                  label="Body"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.body}
                  required
                  onChange={handleChange("body")}
                  style={{
                    marginBottom: 20,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={9}>
                  <MaterialTextField
                    label="Comma Seperated User Ids"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={values.user_ids}
                    onChange={handleChange("user_ids")}
                    style={{
                      marginBottom: 20,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      marginTop: "7px",
                      marginLeft: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Send In-App Notifications?"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <MaterialTextField
                  label="Data (Valid JSON)"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={values.data}
                  onChange={handleChange("data")}
                  style={{
                    marginBottom: 20,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  startIcon={<NotificationAdd />}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Send Notifications
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </form>
        {(values?.title || values?.body) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 400,
                padding: 1.5,
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 1.5,
              }}
            >
              <h4
                style={{
                  margin: 0,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {values?.title || "..."}
              </h4>
              <p
                style={{
                  margin: 0,
                }}
              >
                {values?.body || "..."}
              </p>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default Password;
