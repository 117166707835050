import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <center>
        <CircularProgress />
      </center>
    </div>
  );
};
export default Loader;
