import { useRecordContext } from "react-admin";
import { Button, downloadCSV } from "react-admin";
import ExportIcon from "@mui/icons-material/SystemUpdateAlt";

const GameExport = () => {
  const game = useRecordContext();
  const exportGame = () => {
    try {
      // Leader Board
      let output = "Leaderboard\n";
      output +=
        "Postion," + getNCharacters(game.noOfWinners, "", "", ordinalSuffixOf);
      const results = game.results;
      let result_types = results.result_types;
      output += "Top," + getNArrayElems(getUsers(result_types?.majority));
      output += "Middle," + getNArrayElems(getUsers(result_types?.middle));
      output += "Bottom," + getNArrayElems(getUsers(result_types?.minority));
      // Results
      output += "\nResults\n";
      output += "Participants,Score,Final Rank\n";
      output += normalize2Darray(
        Object.values(
          getTranspose([
            getUsers(Object.keys(results.final_ranks)),
            Object.values(results.sum_score),
            Object.values(results.final_ranks),
          ])
        )
      );
      // Distribution Table
      output += "\nDistribution Table\n";
      output += `Options,${getNCharacters(
        Object.keys(results.distribution_table).length,
        "Q"
      )}`;
      output += normalize2Darray(
        Object.values(
          getTranspose([
            ["A", "B", "C", "D", "E"],
            ...Object.values(results.distribution_table).map((elem) =>
              Object.values(sortObject(elem))
            ),
          ])
        )
      );
      // Score Table
      output += "\nScore Table\n";
      output += `Participants,${getNCharacters(
        Object.keys(results.score_table).length,
        "Score Q"
      )}`;
      output += normalize2Darray(
        Object.values(
          getTranspose([
            getUsers(Object.keys(results.score_table[0])),
            ...Object.values(results.score_table).map((elem) =>
              Object.values(sortObject(elem))
            ),
          ])
        )
      );
      // Feedback
      output += "\nFeedback\n";
      output += `Participants,${getNCharacters(
        Object.keys(game.feedbacks[0].feedback).length,
        "Feedback Q"
      )}`;
      let feedback_users = [];
      let feedbacks = [];
      for (let index = 0; index < game.feedbacks.length; index++) {
        const { feedback, user } = game.feedbacks[index];
        feedbacks.push(feedback);
        feedback_users.push(user.user_id);
      }
      feedback_users = getUsers(feedback_users);
      output += normalize2Darray(
        Object.values(getTranspose([feedback_users, ...[feedbacks]]))
      );
      downloadCSV(output, `game_${game.game_code}_${Date.now()}`);
    } catch (error) {
      console.log(error);
      alert("Error occured while exporting game.");
    }
  };
  const getNArrayElems = (array) => {
    let temp = array.join(",");
    return postProcessString(temp);
  };
  const getNCharacters = (
    count,
    pre_text = "",
    post_text = "",
    pre_condition = null
  ) => {
    let temp = "";
    for (let index = 0; index < count; index++) {
      if (pre_condition)
        temp += `${pre_condition ? pre_condition(index + 1) : post_text}`;
      else temp += `${pre_text}${index + 1}`;
      temp += ",";
    }
    return postProcessString(temp);
  };
  const ordinalSuffixOf = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };
  const postProcessString = (temp) => {
    temp = temp.substring(0, temp.length - 1);
    temp += "\n";
    return temp;
  };
  const getTranspose = (data) => {
    return data.reduce(function (arr, obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          arr[key] = arr[key] || [];
          arr[key].push(obj[key]);
        }
      }
      return arr;
    }, {});
  };
  const getUsers = (array, key = "name") => {
    if (array) return array.map((elem) => game.users[elem][key]);
    return [];
  };
  const normalize2Darray = (array2D) => {
    let temp = "";
    for (let i = 0; i < array2D.length; i++) {
      const array1D = array2D[i];
      temp += array1D.join(",");
      temp += "\n";
    }
    return postProcessString(temp);
  };
  const sortObject = (unordered) => {
    const ordered = Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
    return ordered;
  };
  return (
    <Button
      startIcon={<ExportIcon />}
      onClick={exportGame}
      label="Export Game"
    />
  );
};

export default GameExport;
