import React from "react";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import UserResponseIcon from "@mui/icons-material/Contacts";

const UserResponseList = () => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceField source="user_id" reference="users" label="User Email">
        <TextField
          source="email"
          sx={{
            textTransform: "lowercase",
          }}
        />
      </ReferenceField>
      <TextField source="response" />
    </Datagrid>
  </List>
);

export default {
  list: UserResponseList,
  name: "user_responses",
  icon: UserResponseIcon,
  label: "User Responses",
};
