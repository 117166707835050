import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, query } from "firebase/firestore";
import { app } from "./constants";

const firestore_db = getFirestore(
  initializeApp(app.__DEV__ ? app.devFirebaseConfig : app.firebaseConfig)
);

export const getDocsFromCollection = async (collection_name, where = []) => {
  const doc_refs = await getDocs(
    query(collection(firestore_db, collection_name), ...where)
  );
  const res = [];
  doc_refs.forEach((doc) => {
    res.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return res;
};
