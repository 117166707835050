import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  Create,
  NumberField,
  DateInput,
  NumberInput,
  DateField,
} from "react-admin";
import WinnerPriceIcon from "@mui/icons-material/Paid";
import { app } from "../constants";
import moment from "moment-timezone";

const curreny_options = {
  options: {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  },
};
const WinnerPriceList = () => (
  <List sort={{ field: "index", order: "ASC" }}>
    <Datagrid>
      <DateField source="week_date" />
      <NumberField source="week" label="Week#" />
      <NumberField source="first_prize" {...curreny_options} />
      <NumberField source="second_prize" {...curreny_options} />
      <NumberField source="third_prize" {...curreny_options} />
      <EditButton />
    </Datagrid>
  </List>
);
const WinnerPriceForm = () => {
  return (
    <SimpleForm>
      <DateInput
        source="week_date"
        {...app.inputAttrs}
        label="Any Date of Week"
      />
      <NumberInput source="first_prize" {...app.inputAttrs} />
      <NumberInput source="second_prize" {...app.inputAttrs} />
      <NumberInput source="third_prize" {...app.inputAttrs} />
    </SimpleForm>
  );
};
const transformData = (_data) => {
  let data = { ..._data };
  data["week"] = moment(data.week_date).tz(app.TIMEZONE).isoWeek();
  return data;
};
const WinnerPriceEdit = () => (
  <Edit transform={transformData}>
    <WinnerPriceForm />
  </Edit>
);
const WinnerPriceCreate = () => (
  <Create transform={transformData}>
    <WinnerPriceForm />
  </Create>
);
export default {
  list: WinnerPriceList,
  edit: WinnerPriceEdit,
  create: WinnerPriceCreate,
  name: "league_winner_prices",
  icon: WinnerPriceIcon,
  label: "League Winner Prices",
};
