import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  NumberInput,
  SelectInput,
  ShowButton,
  Show,
  SimpleShowLayout,
  NumberField,
  ImageField,
  FileInput,
  FileField,
} from "react-admin";
import QuestionIcon from "@mui/icons-material/DynamicForm";
import { app } from "../constants";
import GameOptionsFiled from "../components/GameOption";
import DialogDelete from "../components/DialogDelete";

const QuestionList = () => (
  <List sort={{ field: "createdate", order: "DESC" }}>
    <Datagrid
      expand={<QuestionEdit />}
      rowClick="expand"
      bulkActionButtons={false}
      expandSingle
    >
      <TextField source="id" />
      <TextField source="question" />
      <TextField label="QNO Level" source="qno_level" />
      <TextField source="sort_by" />
      <GameOptionsFiled source="themes" />
      <ShowButton />
      <EditButton />
      <DialogDelete model={"questions"} />
    </Datagrid>
  </List>
);
const QuestionForm = ({ disabled_id }) => {
  return (
    <SimpleForm>
      <FileInput
        source="question_image"
        label="Question Media"
        multiple={false}
        accept={["image/*", "video/mp4"]}
        maxSize={20000000}
        defaultValue={""}
      >
        <FileField source="src" title="View Media" target="_blank" />
      </FileInput>
      {!disabled_id && <TextInput source="id" {...app.inputAttrs} />}
      <NumberInput
        min={0}
        max={100}
        step={1}
        label="QNO Level(%)"
        source="qno_level"
        {...app.inputAttrs}
      />
      <SelectInput source="sort_by" choices={app.sort_by} {...app.inputAttrs} />
      <ReferenceArrayInput source="themes" reference="themes" fullWidth>
        <AutocompleteArrayInput
          optionText={"name"}
          source="name"
          {...app.inputAttrs}
        />
      </ReferenceArrayInput>
      <TextInput label="Question Label" source="question" {...app.inputAttrs} />
      <ArrayInput source="options" validate={[required()]}>
        <SimpleFormIterator inline fullWidth>
          <TextInput helperText={false} {...app.inputAttrs} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
const QuestionEdit = (props) => (
  <Edit {...props}>
    <QuestionForm disabled_id />
  </Edit>
);
const QuestionCreate = () => (
  <Create>
    <QuestionForm />
  </Create>
);
export const QuestionsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="question" />
      <ImageField
        source="question_image.src"
        label="Question Image"
        emptyText="--"
      />
      <GameOptionsFiled source="options" />
      <GameOptionsFiled source="themes" />
      <TextField source="sort_by" />
      <NumberField source="qno_level" />
    </SimpleShowLayout>
  </Show>
);
export default {
  list: QuestionList,
  edit: QuestionEdit,
  create: QuestionCreate,
  name: "questions",
  icon: QuestionIcon,
  label: "Questions",
  show: QuestionsShow,
};
