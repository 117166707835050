import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ShowButton,
  Show,
  SimpleShowLayout,
  ImageInput,
  ImageField,
} from "react-admin";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { app } from "../constants";
import OptionsFiled from "../components/Options";
import CountFiled from "../components/Count";

const ThemeList = () => (
  <List>
    <Datagrid>
      <ImageField
        source="thumbnail.src"
        label="Thumbnail"
        sx={{ "& img": { maxWidth: 50, maxHeight: 50, borderRadius: 50 } }}
        emptyText="--"
      />
      <TextField source="id" />
      <TextField source="name" />
      <CountFiled source="questions" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
const ThemeForm = ({ disabled_id }) => {
  return (
    <SimpleForm>
      <TextInput source="id" disabled={disabled_id} {...app.inputAttrs} />
      <TextInput source="name" {...app.inputAttrs} />
      <ImageInput source="thumbnail" label="Theme Thumbnail" multiple={false}>
        <ImageField source="src" title="Theme Thumbnail" />
      </ImageInput>
    </SimpleForm>
  );
};
const ThemeEdit = () => (
  <Edit>
    <ThemeForm disabled_id />
  </Edit>
);
const ThemeCreate = () => (
  <Create>
    <ThemeForm />
  </Create>
);
export const ThemesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ImageField source="thumbnail.src" label="Thumbnail" emptyText="--" />
      <OptionsFiled source="questions" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
export default {
  list: ThemeList,
  edit: ThemeEdit,
  create: ThemeCreate,
  name: "themes",
  label: "Themes",
  icon: PermMediaIcon,
  show: ThemesShow,
};
