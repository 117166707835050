import React from "react";
import { ImageField, ShowButton } from "react-admin";
import GameIcon from "@mui/icons-material/SmartToy";

import {
  ArrayField,
  Datagrid,
  List,
  TextField,
  SimpleShowLayout,
  Show,
  Filter,
  TextInput,
} from "react-admin";
import CountFiled from "../components/Count";
import OptionsFiled from "../components/Options";
import GameExport from "../components/GameExport";

const GameFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search Game Code"
      source="game_code"
      alwaysOn
      variant="outlined"
      fullWidth
    />
  </Filter>
);

export const GameList = () => {
  return (
    <List filters={<GameFilter />} exporter={false}>
      <Datagrid>
        <TextField source="game_code" label="Game Code" />
        <CountFiled source={"questions"} label="Total Questions" />
        <CountFiled source={"users"} label="Total Users" />
        <CountFiled source={"feedbacks"} label="Total Feedbacks" />
        <ShowButton />
        <GameExport />
      </Datagrid>
    </List>
  );
};
export const GameShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="game_code" />
      <ArrayField source="feedbacks">
        <Datagrid bulkActionButtons={false} empty="No feedback found.">
          <ImageField source="user.dp" label="User Avatar" />
          <TextField source="user.name" label="User Name " />
          <OptionsFiled source="feedback" counter />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
export default {
  list: GameList,
  name: "games",
  icon: GameIcon,
  label: "Games",
  show: GameShow,
};
