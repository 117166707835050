import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import TradeIcon from "@mui/icons-material/ShowChart";
import { TradeList } from "../trades";

export default {
  list: <TradeList private_trade={true} />,
  name: "private_trades",
  icon: TradeIcon,
  label: "Trades",
};
