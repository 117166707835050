import React, { useState } from "react";

import { read, utils } from "xlsx";
import { useCreate } from "react-admin";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import DoneIcon from "@mui/icons-material/Done";
import LinearProgress from "@mui/material/LinearProgress";

const BulkTrade = ({ template_trades = false }) => {
  const [create, { isLoading, error }] = useCreate();
  const [progress, setProgress] = useState();
  const loadExcelFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_csv(ws, {
        header: 1,
        FS: "~",
      });
      preProcessExcelData(data);
    };
    reader.readAsBinaryString(file);
  };
  const getMedia = (col, key) => {
    try {
      if (col)
        return { [key]: { src: col, title: col.replace(/^.*[\\\/]/, "") } };
    } catch (error) {
      return {};
    }
    return {};
  };
  const preProcessExcelData = (data) => {
    const rows = data.split("\n");
    const trades = [];
    for (let index = 1; index < rows.length; index++) {
      const row = rows[index];
      const col = row.split("~");
      if (col[0] == "") continue;
      const trade = {
        trade_style: col[0].toLocaleUpperCase(),
        trade_category: col[1].toLocaleLowerCase(),
        title: col[2],
        expire_on: new Date(col[3]),
        entries: 0,
        majority_odds: 0,
        minority_odds: 0,
        min_amount: parseInt(col[4]),
        max_amount: parseInt(col[5]),
        question: col[6],
        ...(col[7] && { ...getMedia(col[7], "question_image") }),
        ...(col[8] && { ...getMedia(col[8], "question_image_thumbnail") }),
        options: [
          {
            text: col[9],
            ...getMedia(col[10], "media"),
            ...getMedia(col[11], "thumbnail"),
          },
          {
            text: col[12],
            ...getMedia(col[13], "media"),
            ...getMedia(col[14], "thumbnail"),
          },
          {
            text: col[15],
            ...getMedia(col[16], "media"),
            ...getMedia(col[17], "thumbnail"),
          },
          {
            text: col[18],
            ...getMedia(col[19], "media"),
            ...getMedia(col[20], "thumbnail"),
          },
        ],
        ...(parseInt(col[21])
          ? {
              bot_responses: [
                {
                  option: "A",
                  number_of_responses: parseInt(col[21]),
                  amount: parseInt(col[22]),
                },
                {
                  option: "B",
                  number_of_responses: parseInt(col[23]),
                  amount: parseInt(col[24]),
                },
                {
                  option: "C",
                  number_of_responses: parseInt(col[25]),
                  amount: parseInt(col[26]),
                },
                {
                  option: "D",
                  number_of_responses: parseInt(col[27]),
                  amount: parseInt(col[28]),
                },
              ],
            }
          : { bot_responses: [] }),
      };
      trades.push(trade);
    }
    pushData(trades);
  };
  const pushData = (trades) => {
    const trade_length = trades.length;
    setProgress(0);
    for (let index = 0; index < trade_length; index++) {
      const trade = trades[index];
      create(template_trades ? "template_trades" : "trades", { data: trade });
      setProgress(((index + 1) / trade_length) * 100);
    }
  };
  return (
    <>
      <Card
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          <center>
            {progress != null && (
              <>
                <LinearProgress variant="determinate" value={progress} />
                <br />
              </>
            )}
            {progress == 100 && (
              <Button startIcon={<DoneIcon />}>Upload Complete</Button>
            )}
            <br />
            <FormControl>
              <Button variant="contained" component="label">
                Bulk Trade Upload
                <input
                  hidden
                  type="file"
                  onChange={loadExcelFile}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Button>
            </FormControl>
          </center>
          <Typography
            variant="caption"
            display="block"
            align="center"
            sx={{
              marginTop: 1,
            }}
          >
            Please choose any excel file for bulk{" "}
            {template_trades ? "template" : ""} trades upload.
          </Typography>
        </CardContent>
      </Card>
      <Typography
        sx={{
          mt: 2,
          fontSize: 14,
        }}
      >
        Please{" "}
        <a href="/trade_format.xlsx" download>
          Download
        </a>{" "}
        bulk {template_trades ? "template" : ""} trade sample for column
        mappings.
      </Typography>
    </>
  );
};
export default BulkTrade;
