import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  NumberInput,
  NumberField,
  FileInput,
  FileField,
  RadioButtonGroupInput,
} from "react-admin";
import CollapseVideoIcon from "@mui/icons-material/OndemandVideo";
import { app } from "../constants";

const CollapseVideoList = () => (
  <List sort={{ field: "index", order: "ASC" }}>
    <Datagrid>
      <TextField source="title" />
      <NumberField source="index" />
      <EditButton />
    </Datagrid>
  </List>
);
const CollapseVideoForm = () => {
  return (
    <SimpleForm>
      <TextInput source="title" {...app.inputAttrs} />
      <NumberInput source="index" {...app.inputAttrs} />
      <FileInput
        source="video"
        label="Video"
        multiple={false}
        accept={["video/mp4"]}
        maxSize={100000000}
      >
        <FileField source="src" title="View Video" target="_blank" />
      </FileInput>
      <RadioButtonGroupInput
        source="aspect_ratio"
        choices={[
          { id: "mobile", name: "9:16" },
          { id: "normal", name: "16:9" },
        ]}
        defaultValue={"mobile"}
      />
    </SimpleForm>
  );
};
const CollapseVideoEdit = () => (
  <Edit>
    <CollapseVideoForm />
  </Edit>
);
const CollapseVideoCreate = () => (
  <Create>
    <CollapseVideoForm />
  </Create>
);
export default {
  list: CollapseVideoList,
  edit: CollapseVideoEdit,
  create: CollapseVideoCreate,
  name: "collapse_videos",
  icon: CollapseVideoIcon,
  label: "Collapse Videos",
};
