import React, { useState } from "react";

import { read, utils } from "xlsx";
import { useCreate, useDataProvider } from "react-admin";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import DoneIcon from "@mui/icons-material/Done";
import LinearProgress from "@mui/material/LinearProgress";
import { app } from "../constants";

const firestore_db = getFirestore(
  initializeApp(app.__DEV__ ? app.devFirebaseConfig : app.firebaseConfig)
);

const BulkQuestions = () => {
  const [create, { isLoading, error }] = useCreate();
  const dataProvider = useDataProvider();
  const [progress, setProgress] = useState();
  //
  const isUrlValid = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return pattern.test(str);
  };
  const loadExcelFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_csv(ws, {
        header: 1,
        FS: "~",
      });
      preProcessExcelData(data);
    };
    reader.readAsBinaryString(file);
  };
  const preProcessExcelData = (data) => {
    const rows = data.split("\n");
    const questions = [];
    let v2 = false;
    for (let index = 1; index < rows.length; index++) {
      const row = rows[index];
      const col = row.split("~");
      if (isUrlValid(col[1])) v2 = true;
      let question = {};
      if (v2)
        question = {
          id: col[0],
          question_image: { src: col[1], name: "image.jpg" },
        };
      else
        question = {
          id: col[0],
          question: col[1],
          options: col.slice(2, 7).filter((op) => op != ""),
          qno_level: parseInt(col[7]),
          sort_by: col[8],
          question_image: { src: col[9], name: "image.jpg" },
          themes: col
            .slice(10, col.length)
            .filter((i) => i != "")
            .map((th) => th.replace("/", "\\")),
        };
      questions.push(question);
    }
    pushData(questions, v2);
  };
  const pushData = async (questions, v2 = false) => {
    const question_length = questions.length;
    setProgress(0);
    for (let index = 0; index < question_length; index++) {
      const question = questions[index];
      try {
        if (v2)
          await updateDoc(doc(firestore_db, "questions", question.id), {
            question_image: question.question_image,
          });
        else await dataProvider.create("questions", { data: question });
      } catch {}
      setProgress(((index + 1) / question_length) * 100);
    }
  };
  return (
    <>
      <Card
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          <center>
            {progress != null && (
              <>
                <LinearProgress variant="determinate" value={progress} />
                <br />
              </>
            )}
            {progress == 100 && (
              <Button startIcon={<DoneIcon />}>Upload Complete</Button>
            )}
            <br />
            <FormControl>
              <Button variant="contained" component="label">
                Bulk Question Upload
                <input hidden type="file" onChange={loadExcelFile} />
              </Button>
            </FormControl>
          </center>
          <Typography
            variant="caption"
            display="block"
            align="center"
            sx={{
              marginTop: 1,
            }}
          >
            Please choose any excel file for bulk questions upload.
          </Typography>
        </CardContent>
      </Card>
      <Typography
        sx={{
          mt: 2,
          fontSize: 14,
        }}
      >
        - Please{" "}
        <a href="/game_question_images.xlsx" download>
          Download
        </a>{" "}
        bulk Question Images sample for column mappings.
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          mt: 1,
        }}
      >
        - Please{" "}
        <a href="/questions_format.xlsx" download>
          Download
        </a>{" "}
        bulk Questions sample for column mappings.
      </Typography>
    </>
  );
};
export default BulkQuestions;
