import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  Show,
  SimpleShowLayout,
  useDataProvider,
  Form,
  TextField,
  useRedirect,
} from "react-admin";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import CategoryIcon from "@mui/icons-material/DynamicForm";
import ChipsInput from "../components/ChipInput";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField as MUTextField,
} from "@mui/material";
import TagsField from "../components/TagsField";
import Loader from "../components/Loader";
import {
  BulkEditTradeButton,
  LibraryBulkActions,
  LibraryEdit,
} from "../library";

const CategoryList = () => (
  <List>
    <Datagrid bulkActionButtons={<LibraryBulkActions />}>
      <TextField source="title" />
      <TagsField source="tags" />
      <BulkEditTradeButton buttonLabel="Edit" />
    </Datagrid>
  </List>
);

const CategoryCreate = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState("");
  const [allTags, setAlltags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bLoading, setBLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await dataProvider.getList("library", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "created_at", order: "DESC" },
      });
      let tags = data.map((lib) => lib.tags);
      // Unique tags
      tags = [...new Set([].concat(...tags))];
      setAlltags(tags);
      setLoading(false);
    })();
  }, []);
  const createCategory = async () => {
    setBLoading(true);
    const category = { title, tags };
    await dataProvider.create("library_categories", { data: category });
    redirect("/library_categories");
    setBLoading(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card>
            <CardContent>
              <Form onSubmit={createCategory}>
                <Grid container>
                  <Grid item xs={12}>
                    <MUTextField
                      required
                      variant="outlined"
                      fullWidth
                      label="Category Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ChipsInput {...{ tags, setTags, allTags }} />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Button
                      variant="outlined"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      {bLoading ? <CircularProgress size={20} /> : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
export const CategorysShow = () => (
  <Show>
    <SimpleShowLayout></SimpleShowLayout>
  </Show>
);
export default {
  list: CategoryList,
  edit: () => <LibraryEdit library_categories />,
  create: CategoryCreate,
  name: "library_categories",
  icon: CategoryIcon,
  label: "Mappings",
  // show: CategorysShow,
};
