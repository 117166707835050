import * as React from "react";
import { Layout, usePermissions } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";

export default (props) => {
  const { permissions } = usePermissions();
  return (
    <Layout
      {...props}
      appBar={AppBar}
      {...{
        ...(permissions?.role && {
          menu: Menu,
        }),
      }}
    />
  );
};
