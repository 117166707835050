import React, { useEffect, useState } from "react";

import SignUpGiftCardsIcon from "@mui/icons-material/Redeem";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { where } from "firebase/firestore";
import { getDocsFromCollection } from "../utils";
import { Loading } from "react-admin";
import { getCloudFunctionUrl } from "../trades";
import axios from "axios";
import { app } from "../constants";
//
const gift_card_amount = 25;
const SignUpGiftCardsList = () => {
  const [loading, setLoadinng] = useState(true);
  const [users, setUsers] = useState([]);
  const [redeems, setRedeems] = useState([]);
  //
  useEffect(() => {
    (async () => {
      let _users = await getDocsFromCollection("users", [
        where("stats.total_trades", ">=", 10),
      ]);
      _users = _users
        .filter((u) => u.referral_code)
        .sort(
          (a, b) =>
            Number(a.referral_gift_card_sent || false) -
            Number(b.referral_gift_card_sent || false)
        );
      setUsers([]);
      let _gift_card_requests = await getDocsFromCollection(
        "gift_card_requests",
        [where("v2", "==", true)]
      );
      _gift_card_requests = _gift_card_requests.map((re) => ({
        ...re,
        gift_card_request_id: re.id,
        id: re.user_id,
      }));
      console.log(_gift_card_requests);
      setRedeems(_gift_card_requests);
      setUsers(_users);
      setLoadinng(false);
    })();
  }, []);
  //
  const CreateGiftCardButton = ({
    id,
    referral_gift_card_sent,
    stats,
    redeem,
    redeem_price,
    email,
    gift_card_request_id,
    card_sent,
  }) => {
    const [loading, setLoading] = useState(false);
    const price = redeem_price || gift_card_amount;
    const createGiftCard = async () => {
      setLoading(true);
      let url =
        getCloudFunctionUrl() +
        `/createGiftCard?user_id=${id}&mode=${
          redeem ? "REDEEM" : "REFERRAL"
        }&amount=${price}&total_trades=${stats?.total_trades || 0}`;
      if (redeem)
        url += `&email=${email}&gift_card_request_id=${gift_card_request_id}`;
      Promise.resolve(axios.get(url))
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (referral_gift_card_sent) return "Gift Card Sent";
    if (redeem && card_sent) return "Gift Card Sent";
    return (
      <Button variant="outlined" onClick={createGiftCard}>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          `Send ${app.currency}${price} Gift Card`
        )}
      </Button>
    );
  };
  //
  if (loading)
    return <Loading loadingSecondary="Please wait, data is fetching..." />;
  return (
    <div>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="p">
                  Referrals Requests
                </Typography>
                <hr />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User Id</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Referral Code</TableCell>
                      <TableCell>Total Trades</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.referral_code}</TableCell>
                        <TableCell>
                          {(user?.stats || {})?.total_trades}
                        </TableCell>
                        <TableCell>
                          <CreateGiftCardButton {...user} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="p">
                  Redeem Requests
                </Typography>
                <hr />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User Id</TableCell>
                      <TableCell>Requested Email</TableCell>
                      <TableCell>Redeem Price</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {redeems.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>No data to show.</TableCell>
                      </TableRow>
                    )}
                    {redeems.map((user) => (
                      <TableRow>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.redeem_price}</TableCell>
                        <TableCell>
                          <CreateGiftCardButton {...user} redeem />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default {
  list: SignUpGiftCardsList,
  name: "signup_gift_cards",
  icon: SignUpGiftCardsIcon,
  label: "Send Gift Cards",
};
