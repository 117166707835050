import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  downloadCSV,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import WinnerTrackIcon from "@mui/icons-material/Insights";
import FileCopyIcon from "@mui/icons-material/ContentCopy";
//
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import jsonExport from "jsonexport/dist";
import { getCloudFunctionUrl } from "../trades";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { app } from "../constants";

const CreateGiftCardButton = ({
  user_id,
  position,
  dataLoading,
  giftCardTracks,
  cricket_week,
}) => {
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const currentWeek = moment().tz(app.TIMEZONE).isoWeek();
  const createGiftCard = async () => {
    setLoading(true);
    let url =
      getCloudFunctionUrl() +
      `/createGiftCard?user_id=${user_id}&week=${record.week}&position=${position}&mode=POSITION`;
    if (cricket_week) url += `&cricket_week=${cricket_week}`;
    Promise.resolve(axios.get(url))
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const key = `${record.week}_${position}_${user_id}`;
  if (!record) return;
  if (dataLoading) return <CircularProgress size={20} />;
  if (currentWeek == record.week) return "--";
  if (giftCardTracks[key]?.status) return "Gift Card Sent";
  return (
    <div>
      <Button variant="outlined" onClick={createGiftCard}>
        {loading ? <CircularProgress size={20} /> : "Send Gift Card"}
      </Button>
      {giftCardTracks[key]?.out_of_stock && (
        <p
          style={{
            display: "inline",
            color: "#dc3545",
            marginLeft: 10,
          }}
        >
          Out of Stock
        </p>
      )}
    </div>
  );
};

const PositionTable = ({
  record,
  dataLoading,
  giftCardTracks,
  cricket_week,
}) => {
  const copyToClipboard = (userId) => {
    const tempInput = document.createElement("input");
    tempInput.value = userId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };
  const ordinalToNumber = (ordinal) => {
    switch (ordinal) {
      case "first":
        return 1;
      case "second":
        return 2;
      case "third":
        return 3;
      case "forth":
        return 4;
      case "fifth":
        return 5;
      case "sixth":
        return 6;
      case "seventh":
        return 7;
      case "eighth":
        return 8;
      case "ninth":
        return 9;
      case "tenth":
        return 10;
      default:
        return Number.MAX_VALUE;
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Position</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Total Played</TableCell>
            <TableCell>Ratings</TableCell>
            <TableCell>User Id</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Referral Code</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(record.positions)
            .sort((a, b) => ordinalToNumber(a) - ordinalToNumber(b))
            .map((position, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{position}</TableCell>
                <TableCell>{record.positions[position].name}</TableCell>
                <TableCell>{record.positions[position].played}</TableCell>
                <TableCell>
                  {record.positions[position].ratings.toFixed(2)}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "inline",
                    }}
                  >
                    <Button
                      onClick={() =>
                        copyToClipboard(record.positions[position].user_id)
                      }
                    >
                      <FileCopyIcon />
                    </Button>
                  </div>
                  {record.positions[position].user_id}
                </TableCell>
                <TableCell>
                  {record.positions[position].email && (
                    <div
                      style={{
                        display: "inline",
                      }}
                    >
                      <Button
                        onClick={() =>
                          copyToClipboard(
                            record.positions[position].email || "--"
                          )
                        }
                      >
                        <FileCopyIcon />
                      </Button>
                    </div>
                  )}
                  {record.positions[position].email || "--"}
                </TableCell>
                <TableCell>
                  {record.positions[position].referral_code || "--"}
                </TableCell>
                <TableCell
                  style={{
                    width: 400,
                  }}
                >
                  <CreateGiftCardButton
                    user_id={record.positions[position].user_id}
                    position={position}
                    dataLoading={dataLoading}
                    giftCardTracks={giftCardTracks}
                    cricket_week={cricket_week}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const WinnerTrackList = ({ is_cricket }) => {
  const [loading, setLoading] = useState(false);
  const [giftCardTracks, setGiftCardTracks] = useState({});
  const dataProvider = useDataProvider();
  const formatExportData = (data) => {
    const { week, positions, all_track } = data;

    let position_mappings = {};
    Object.keys(positions).map((position) => {
      let user = positions[position];
      position_mappings[user.user_id] = position;
    });

    return Object.values(all_track).map((user) => {
      const { name, played, ratings, user_id, email, referral_code } = user;
      return {
        week,
        position: position_mappings[user_id] || "--",
        name,
        played,
        ratings: ratings.toFixed(2),
        user_id,
        email,
        referral_code,
      };
    });
  };
  const exporter = (tracks) => {
    tracks = tracks.map((track) => formatExportData(track));
    tracks = [].concat(...tracks);
    jsonExport(
      tracks,
      {
        headers: [
          "week",
          "position",
          "name",
          "played",
          "ratings",
          "user_id",
          "email",
        ],
      },
      (err, csv) => {
        downloadCSV(csv, "weekly_tracks");
      }
    );
  };
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await dataProvider.getList("gift_card_tracks", {
          pagination: { page: 1, perPage: 1000 },
          sort: {},
        });
        if (data.length > 0) {
          setGiftCardTracks(
            data.reduce((acc, drip) => {
              acc[drip.id] = drip;
              delete drip.id;
              return acc;
            }, {})
          );
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <List
      sort={{ field: "week", order: is_cricket ? "DESC" : "ASC" }}
      exporter={exporter}
      {...(is_cricket && {
        filter: {
          completed: true,
        },
      })}
    >
      <Datagrid>
        {!is_cricket && <TextField source="week" label="Week#" />}
        {is_cricket && (
          <FunctionField
            source="week"
            label="Sr#"
            render={(record) => record.week + 1}
          />
        )}
        {is_cricket && (
          <TextField source="cricket_week.name" label="Week Name" />
        )}
        {is_cricket && (
          <FunctionField
            label="Week Date Range"
            render={(record) =>
              `${moment(record.cricket_week.start_date).format(
                "DD/MM/YYYY"
              )} - ${moment(record.cricket_week.end_date).format("DD/MM/YYYY")}`
            }
          />
        )}
        <FunctionField
          label="Positions"
          render={(record) => (
            <PositionTable
              record={record}
              dataLoading={loading}
              giftCardTracks={giftCardTracks}
              {...(is_cricket && {
                cricket_week: (record?.cricket_week || {})?.id,
              })}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default {
  list: WinnerTrackList,
  name: "weekly_tracks",
  icon: WinnerTrackIcon,
  label: "Weekly Tracks",
};
