import React, { useState, useEffect } from "react";
import {
  Form,
  List,
  Datagrid,
  SimpleForm,
  Create,
  ImageInput,
  ImageField,
  useNotify,
  useDataProvider,
  useRedirect,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
  BulkDeleteButton,
  Button,
  Filter,
  DateField,
  FilterLiveSearch,
  useUnselectAll,
} from "react-admin";
import {
  Button as MUButton,
  Card,
  CardContent,
  Grid,
  TextField as MUTextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import ChipsInput from "../components/ChipInput";
import LibraryIcon from "@mui/icons-material/PhotoLibrary";
import EditIcon from "@mui/icons-material/Edit";
import TagsField from "../components/TagsField";
import { useNavigate } from "react-router-dom";
import { app } from "../constants";

import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";

const LibraryFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search Library Images"
      source="image.title"
      fullWidth={true}
      variant="outlined"
      alwaysOn
    />
    <FilterLiveSearch
      source="tags"
      fullWidth={true}
      variant="outlined"
      alwaysOn
      label="Tags Search"
    />
  </Filter>
);

export const BulkEditTradeButton = ({ buttonLabel, selectedIds }) => {
  const media = useRecordContext();
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<EditIcon />}
      onClick={() => {
        let ids = [];
        if (!media) ids = [...selectedIds];
        else ids = [media.id];
        navigate(`edit?ids=${ids.join(",")}`);
      }}
      label={buttonLabel}
    />
  );
};
export const LibraryBulkActions = ({ selectedIds }) => (
  <>
    <BulkEditTradeButton selectedIds={selectedIds} buttonLabel="Bulk Edit" />
    <BulkDeleteButton />
  </>
);

const LibraryList = () => (
  <List filters={<LibraryFilter />}>
    <Datagrid bulkActionButtons={<LibraryBulkActions />}>
      <TextField source="image.title" />
      <ImageField source="image.src" label="Library Image" emptyText="--" />
      <TagsField source="tags" />
      <DateField source="created_at" />
      <BulkEditTradeButton buttonLabel="Edit" />
    </Datagrid>
  </List>
);
const LibraryCreate = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = async (formData) => {
    notify("Creating library records...");
    for (let i = 0; i < formData.library.length; i++) {
      const { images, private_tags } = formData.library[i];
      await Promise.all(
        images.map((image) => {
          let tags = [
            ...private_tags.split(","),
            ...formData.global_tags.split(","),
          ];
          tags = [...new Set([...tags])].map((tag) =>
            tag.toLocaleLowerCase().trim()
          );
          return dataProvider.create("library", {
            data: { image, tags },
          });
        })
      );
    }

    notify("Records created successfully!", "success");
    redirect("/library");
  };
  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <ArrayInput source="library" label="Add Library Section">
          <SimpleFormIterator inline fullWidth>
            <ImageInput
              source="images"
              label="Library Images"
              multiple
              accept={["image/*"]}
            >
              <ImageField source="src" title="Library" />
            </ImageInput>
            <TextInput
              source="private_tags"
              {...app.inputAttrs}
              label="Private Tags"
              placeholder="Comma seperated private tags"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput
          source="global_tags"
          {...{ fullWidth: true, variant: "outlined" }}
          label="Global Tags"
          placeholder="Comma seperated global tags"
        />
      </SimpleForm>
    </Create>
  );
};
export const LibraryEdit = ({ library_categories }) => {
  const route = library_categories ? "library_categories" : "library";
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const [libraries, setLibraries] = useState([]);
  const [tags, setTags] = useState([]);
  const [newTags, setNewTags] = useState("");
  const [allTags, setAlltags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bLoading, setBLoading] = useState(false);
  const unselectAll = useUnselectAll(route);
  const { search } = useLocation();
  useEffect(() => {
    (async () => {
      let ids = new URLSearchParams(search).get("ids");
      ids = ids.split(",");
      if (ids.length == 0) redirect(`/${route}`);
      try {
        let { data } = await dataProvider.getMany(route, {
          ids,
        });
        if (data.length == 0) throw "";
        let _tags = [];
        if (data.length == 1) _tags = data[0]?.tags || [];
        setTags(_tags);
        setAlltags(_tags);
        setLibraries(data);
      } catch (error) {
        notify("Sorry, record not found.");
        redirect(`/${route}`);
      }
      setLoading(false);
    })();
  }, []);
  const updateLibrary = async () => {
    setBLoading(true);
    try {
      let new_tags = [...tags, ...newTags.split(",")];
      new_tags = [...new Set(new_tags)].filter((i) => i != "");
      const totalLibraries = libraries.length;
      for (let i = 0; i < totalLibraries; i++) {
        const library = libraries[i];
        if (totalLibraries > 1) {
          new_tags = [...new_tags, ...library.tags];
          new_tags = [...new Set(new_tags)];
        }
        let need_to_remove_tag = false;
        new_tags = new_tags.map((tag) => {
          let _tag = tag;
          if (tag.startsWith("-")) {
            need_to_remove_tag = true;
            _tag = tag.slice(1);
          }
          return _tag.toLocaleLowerCase().trim();
        });
        if (need_to_remove_tag)
          new_tags = new_tags.filter(
            (item, index) =>
              new_tags.indexOf(item) === index &&
              new_tags.lastIndexOf(item) === index
          );
        await dataProvider.update(route, {
          id: library.id,
          data: { tags: new_tags },
          previousData: library,
        });
      }
      notify("Record updated.", { type: "success" });
    } catch (error) {
      notify("Error on updating record.", { type: "error" });
    } finally {
      redirect(`/${route}`);
      unselectAll();
      setBLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card>
            <CardContent>
              <Form onSubmit={updateLibrary}>
                <Grid container>
                  <Grid item xs={12}>
                    <MUTextField
                      required={libraries.length > 1}
                      variant="outlined"
                      fullWidth
                      label={
                        libraries.length > 1
                          ? "Bulk comma seperated new tags"
                          : "Comma seperated new tags (optional)"
                      }
                      value={newTags}
                      onChange={(e) => setNewTags(e.target.value)}
                    />
                  </Grid>
                  {libraries.length == 1 && (
                    <Grid item xs={12}>
                      <ChipsInput {...{ tags, setTags, allTags }} />
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <MUButton
                      variant="outlined"
                      startIcon={<SaveIcon />}
                      type="submit"
                    >
                      {bLoading ? <CircularProgress size={20} /> : "Save"}
                    </MUButton>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
export default {
  list: LibraryList,
  create: LibraryCreate,
  edit: LibraryEdit,
  name: "library",
  label: "Media",
  icon: LibraryIcon,
};
