import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  required,
  NumberInput,
  SelectInput,
  ShowButton,
  Show,
  SimpleShowLayout,
  NumberField,
  ImageInput,
  ImageField,
} from "react-admin";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import { app } from "../constants";

const VideoList = () => (
  <List sort={{ field: "createdate", order: "DESC" }}>
    <Datagrid>
      <ImageField
        source="thumbnail.src"
        label="Thumbnail"
        sx={{ "& img": { maxWidth: 100, maxHeight: 100 } }}
        emptyText="--"
      />
      <TextField source="title" />
      <TextField source="youtube_id" label="YouTube Id" />
      <EditButton />
    </Datagrid>
  </List>
);
const VideoForm = () => {
  return (
    <SimpleForm>
      <TextInput source="title" {...app.inputAttrs} />
      <TextInput source="youtube_id" {...app.inputAttrs} label="Youtube ID" />
      <ImageInput source="thumbnail" label="Video Thumbnail" multiple={false}>
        <ImageField source="src" title="Video Thumbnail" />
      </ImageInput>
    </SimpleForm>
  );
};
const VideoEdit = () => (
  <Edit>
    <VideoForm />
  </Edit>
);
const VideoCreate = () => (
  <Create>
    <VideoForm />
  </Create>
);
export const VideosShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);
export default {
  list: VideoList,
  edit: VideoEdit,
  create: VideoCreate,
  name: "videos",
  icon: VideoIcon,
  label: "Videos",
  // show: VideosShow,
};
