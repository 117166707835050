import React, { useState } from "react";

import { read, utils } from "xlsx";
import { useDataProvider } from "react-admin";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import DoneIcon from "@mui/icons-material/Done";
import LinearProgress from "@mui/material/LinearProgress";

const BulkGiftCards = () => {
  const dataprovider = useDataProvider();
  const [progress, setProgress] = useState();
  const loadExcelFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_csv(ws, {
        header: 1,
        FS: "~",
      });
      preProcessExcelData(data);
    };
    reader.readAsBinaryString(file);
  };
  const preProcessExcelData = (data) => {
    const rows = data.split("\n");
    const giftCards = [];
    for (let index = 1; index < rows.length; index++) {
      const row = rows[index];
      const col = row.split("~");
      if (col[0] == "") continue;
      const giftCard = {
        link: col[1],
        code: col[2],
        amount: parseFloat(col[3]),
        expire_on: new Date(col[4]),
        reference_id: col[5].replace(/"/g, ""),
        order_no: col[6],
        status: col[7].toUpperCase(),
      };
      giftCard.id = giftCard.reference_id;
      giftCards.push(giftCard);
    }
    pushData(giftCards);
  };
  const pushData = async (giftCards) => {
    const gift_card_length = giftCards.length;
    setProgress(0);
    for (let index = 0; index < gift_card_length; index++) {
      const gift_card = giftCards[index];
      let alread_exists = false;
      let previous_gift_card;
      try {
        const response = await dataprovider.getOne("gift_cards", {
          id: gift_card.id,
        });
        previous_gift_card = response.data;
        alread_exists = true;
      } catch {}
      if (!alread_exists)
        dataprovider.create("gift_cards", { data: gift_card });
      else
        dataprovider.update("gift_cards", {
          id: gift_card.id,
          data: gift_card,
          previousData: previous_gift_card,
        });
      setProgress(((index + 1) / gift_card_length) * 100);
    }
  };
  return (
    <>
      <Card
        sx={{
          marginTop: 2,
        }}
      >
        <CardContent>
          <center>
            {progress != null && (
              <>
                <LinearProgress variant="determinate" value={progress} />
                <br />
              </>
            )}
            {progress == 100 && (
              <Button startIcon={<DoneIcon />}>Upload Complete</Button>
            )}
            <br />
            <FormControl>
              <Button
                variant="contained"
                component="label"
                disabled={progress >= 0 && progress < 100}
              >
                Bulk Gift Cards Upload
                <input
                  hidden
                  type="file"
                  onChange={loadExcelFile}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Button>
            </FormControl>
          </center>
          <Typography
            variant="caption"
            display="block"
            align="center"
            sx={{
              marginTop: 1,
            }}
          >
            Please choose any excel file for bulk gift cards upload.
          </Typography>
        </CardContent>
      </Card>
      <Typography
        sx={{
          mt: 2,
          fontSize: 14,
        }}
      >
        Please{" "}
        <a href="/gift_cards_format.xlsx" download>
          Download
        </a>{" "}
        bulk gift cards sample for column mappings.
      </Typography>
    </>
  );
};
export default BulkGiftCards;
