import { Delete } from "@mui/icons-material";
import { useState } from "react";
import { Button, Confirm, useRecordContext, useRefresh } from "react-admin";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, deleteDoc } from "firebase/firestore";
import { app } from "../constants";

const DialogDelete = ({ model }) => {
  const _app = initializeApp(
    app.__DEV__ ? app.devFirebaseConfig : app.firebaseConfig
  );
  const record = useRecordContext();
  const refresh = useRefresh();
  //
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  //
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    if (record && record.id) {
      const db = getFirestore(_app);
      await deleteDoc(doc(db, model, record.id));
      refresh();
    }
    setOpen(false);
    setIsLoading(false);
  };
  //
  return (
    <>
      <Button
        label="Delete"
        onClick={handleClick}
        color="error"
        startIcon={<Delete />}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Delete Confirmation`}
        content="Are you sure you want to delete this item?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default DialogDelete;
