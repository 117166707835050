import { useRecordContext } from "react-admin";

const CountFiled = ({ source }) => {
  const record = useRecordContext();
  try {
    return record[source].length || Object.keys(record[source]).length;
  } catch (error) {
    return 0;
  }
};

export default CountFiled;
