import * as React from "react";
import { AppBar } from "react-admin";
import Typography from "@mui/material/Typography";
import logo from "./logo.png";

const CustomAppBar = (props) => {
  return (
    <AppBar {...props} elevation={1}>
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      ></Typography>
      <img
        src={logo}
        style={{
          width: 100,
        }}
      />
      <span
        style={{
          flex: 1,
        }}
      />
    </AppBar>
  );
};

export default CustomAppBar;
