import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  NumberInput,
  NumberField,
} from "react-admin";
import TradeCategoryIcon from "@mui/icons-material/ViewList";
import { app } from "../constants";

const TradeCategoryList = () => (
  <List sort={{ field: "createdate", order: "DESC" }}>
    <Datagrid>
      <ImageField
        source="icon.src"
        label="Icon"
        sx={{ "& img": { maxWidth: 50, maxHeight: 50 } }}
        emptyText="--"
      />
      <TextField source="id" label="Slug" />
      <TextField source="title" />
      <NumberField source="priority" emptyText="1" />
      <EditButton />
    </Datagrid>
  </List>
);
const TradeCategoryForm = () => {
  return (
    <SimpleForm>
      <TextInput
        source="id"
        label="Category Slug"
        {...app.inputAttrs}
        placeholder="cricket"
      />
      <TextInput source="title" {...app.inputAttrs} />
      <NumberInput
        source="priority"
        {...app.inputAttrs}
        defaultValue={1}
        min={1}
      />
      <ImageInput source="icon" label="Icon" multiple={false}>
        <ImageField source="src" title="Icon" />
      </ImageInput>
    </SimpleForm>
  );
};
const TradeCategoryEdit = () => (
  <Edit>
    <TradeCategoryForm />
  </Edit>
);
const TradeCategoryCreate = () => (
  <Create>
    <TradeCategoryForm />
  </Create>
);
export default {
  list: TradeCategoryList,
  edit: TradeCategoryEdit,
  create: TradeCategoryCreate,
  name: "template_trade_categories",
  icon: TradeCategoryIcon,
  label: "Trade Categories",
};
