import React from "react";
import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

const TagsField = ({ source }) => {
  const { tags } = useRecordContext();
  return (
    <>
      {(tags || []).map((tag, index) => (
        <div style={{ display: "inline-block" }}>
          <Chip
            label={tag}
            key={index}
            sx={{ marginRight: 1, marginBottom: 1 }}
          />
        </div>
      ))}
    </>
  );
};

export default TagsField;
