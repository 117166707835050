import React from "react";
//
//
import element_0 from "./images/element_0.png";
//
import winning_objective_left from "./images/winning_objective/left.png";
import winning_objective_left__bottom from "./images/winning_objective/left__bottom.png";
import winning_objective_left_bottom from "./images/winning_objective/left_bottom.png";
import winning_objective_right from "./images/winning_objective/right.png";
import winning_objective_right_bottom from "./images/winning_objective/right_bottom.png";
//
import open_left from "./images/open_trades/left.png";
import open_right from "./images/open_trades/right.png";
//
import qunions_game from "./images/qunions_game.png";
import playing_game_left from "./images/playing_game/left.png";
import playing_game_right from "./images/playing_game/right.png";
import playing_game_bottom from "./images/playing_game_bottom.png";
import joining_the_game from "./images/joining_the_game.png";
import game_play from "./images/game_play.png";
import tap from "./images/tap.svg";
//
import HowToPlayContent from "./HowToPlayContent";
import FAQ from "./FAQs";
//

const Play = () => {
  const Section = ({
    style,
    title,
    textColor = "#FFF",
    description,
    Images,
  }) => {
    return (
      <div style={style}>
        <h3
          className="text-align-center"
          style={{
            color: textColor,
            margin: 0,
          }}
        >
          {title}
        </h3>
        <p
          style={{
            color: textColor,
          }}
        >
          {description}
        </p>
        <Images />
      </div>
    );
  };
  const Title = ({ t1, t2 }) => {
    return (
      <div
        style={{
          background:
            "linear-gradient(88.24deg, #F2AE00 0.54%, #FFE600 99.82%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0px",
        }}
      >
        <p style={{ fontSize: 22 }} className="theme-font bold">
          {t1}
        </p>
        <p style={{ fontSize: 18, marginLeft: 5 }} className="theme-font bold">
          {t2}
        </p>
      </div>
    );
  };
  const Paragraph = ({ text, style }) => {
    return (
      <p
        style={{
          color: "#FFF",
          ...style,
        }}
      >
        {text}
      </p>
    );
  };
  const common_padding = "30px 10px 30px 10px";
  return (
    <>
      <div
        style={{
          paddingBottom: 100,
        }}
      >
        <Section
          title={"Qunions – Questions n Opinions"}
          style={{
            padding: "30px 10px 222px 10px",
            background:
              "linear-gradient(0.32deg, #A74F48 0.27%, #FF867A 21.02%, #CB465F 58.3%, #AB2455 98.61%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
          }}
          description="Welcome to Qunions - a Questions and Opinions platform! Here, there are only opinions to questions – no factual one correct answer! Choose an opinion that you think/analyze would be most popular or most unique in your group or in an open event. There are different Qunions formats - Contests, Events and Game. For different  formats, strategize as per the game’s winning objective and play with others psychology to win."
          Images={() => (
            <img
              src={element_0}
              style={{
                position: "absolute",
                right: 10,
                width: 256,
                height: 235,
              }}
            />
          )}
        />
        <Section
          title={"Winning Objective"}
          style={{
            padding: common_padding,
            background: "#FFF",
          }}
          textColor="#000"
          description="“Be the most popular or the most unique in your opinions”. For all Qunions formats, this underlying principle is same, but application of this principle varies from format to format."
          Images={() => (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  marginRight: 10,
                }}
              >
                <img
                  src={winning_objective_left}
                  className="img"
                  style={{
                    width: "100%",
                    marginTop: -10,
                  }}
                />
                <img
                  src={winning_objective_left__bottom}
                  className="img"
                  style={{
                    width: "100%",
                    marginTop: -2,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "22%",
                    paddingRight: "5%",
                    marginTop: "-30px",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{ margin: 0, color: "#FFF", fontSize: 14 }}
                    className="theme-font"
                  >
                    Phenomenal
                  </p>
                  <p
                    style={{ margin: 0, color: "#FFF", fontSize: 16 }}
                    className="theme-font bold"
                  >
                    42%
                  </p>
                </div>
                <img
                  style={{ marginTop: 17 }}
                  src={winning_objective_left_bottom}
                  className="img"
                />
              </div>
              <div
                style={{
                  flex: 1,
                  marginLeft: 10,
                }}
              >
                <img src={winning_objective_right} className="img" />
                <img
                  src={winning_objective_right_bottom}
                  className="img"
                  style={{
                    marginTop: 7,
                  }}
                />
              </div>
            </div>
          )}
        />
        <Title t1="Format 1 :" t2="Qunions – Open Contests" />
        <Section
          style={{
            padding: "20px 10px 218px 10px",
            background:
              " linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(180deg, #43349F 0%, #1BAA76 100%)",
          }}
          description="Qunions Open Contests are post-event questions and opinions about a recently concluded event, where you can weigh in on an opinion from a set of different opinions analyzing that event. For all contests question, there are only opinions – no factual one correct answer! Choose an opinion that you think/analyze would be the most popular (Majority) or the most unique (Minority) for that question. If your chosen opinion is in Majority or Minority, you will win as per Majority return% or Minority return% on your investment for that contest. Remember, a Contest is only active (open) for a certain duration, so be sure to chip in with your opinion before it is closed. Once the Contest is closed, results are declared on the App."
          Images={() => (
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: 0,
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <img
                  src={open_left}
                  className="img"
                  style={{
                    width: 85,
                    marginRight: -15,
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <img
                  src={open_right}
                  className="img"
                  style={{
                    width: 238,
                  }}
                />
              </div>
            </div>
          )}
        />
        <div
          style={{
            margin: 10,
          }}
        >
          <HowToPlayContent />
        </div>
        <Title t1="Format 2 :" t2="Qunions Game" />
        <Section
          style={{
            padding: common_padding,
            background: "linear-gradient(180deg, #808AFF 0%, #BE1279 100%)",
          }}
          description="Game is played within your own group of friends, colleagues, family, etc. You can either host the game or join as a participant."
          Images={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={qunions_game} className="img" />
              <Paragraph
                text={"Game Jargon"}
                style={{
                  textAlign: "center",
                }}
              />
              <Paragraph
                text={
                  "Qunions – Fun, interactive, psychological, strategical, eventful game of Questions and Opinions."
                }
                style={{
                  marginTop: 0,
                }}
              />
              <Paragraph
                text={
                  "QnOs – The set of questions (questionnaire) along with their opinions to be played in the game."
                }
                style={{
                  marginTop: 0,
                }}
              />
              <Paragraph
                text={"QnOers – All Players playing the Qunions game."}
              />
            </div>
          )}
        />
        <Section
          title={"Playing the Game"}
          style={{
            padding: "30px 10px 0px 10px",
            background: "#FFF",
          }}
          textColor="#000"
          Images={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Paragraph
                text={
                  "Hosting the game is super fun! You get to set the QnOs for your fellow QnOers. Just click ‘(+) Create Game’."
                }
                style={{ color: "#000" }}
              />
              <Paragraph
                text={
                  "From a huge collection of exciting questions across themes/genres, you can pick the ones that you deem fit for your group. If you have something in mind of your own or want to make some changes to existing library, you can edit or add the questions and/or opinions. Once you are done setting the QnOs for an event, you can choose the winning objective and prizes for the party!"
                }
                style={{ color: "#000" }}
              />
              <Paragraph
                text={
                  "Next, let’s start inviting the fellow QnOers by sharing the game code."
                }
                style={{ color: "#000" }}
              />
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={playing_game_left}
                    className="img"
                    style={{
                      width: "80%",
                    }}
                  />
                  <img
                    src={playing_game_bottom}
                    className="img"
                    style={{
                      width: "100%",
                      marginTop: -49,
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={playing_game_right}
                    className="img"
                    style={{
                      width: "80%",
                      marginTop: -35,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        />
        <Section
          title={"Joining the Game"}
          style={{
            padding: "30px 10px 0px 10px",
            background: "linear-gradient(180deg, #808AFF 0%, #BE1279 100%)",
            marginTop: -10,
          }}
          description="Joining the game is super easy! Just click ‘Join Game’ and enter the room code that your host has shared with you already. Game will begin shortly at host’s discretion."
          Images={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={joining_the_game}
                className="img"
                style={{ marginTop: 10 }}
              />
              <div>
                <div
                  style={{
                    width: "42%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: -25,
                  }}
                >
                  <img
                    src={tap}
                    className="img"
                    style={{ marginTop: 4, width: 49, height: 58 }}
                  />
                </div>
              </div>
            </div>
          )}
        />
        <Section
          title={"Game Play"}
          style={{
            padding: common_padding,
            background: "#FFF",
            marginTop: -10,
          }}
          textColor="#000"
          description="Okay, let’s get started! All QnOers will be presented with the same question, one at a time, from the QnOs that their host finalized. To every question, QnOers will choose their opinion that they think most QnOers will choose or least QnOers will choose to either maximize or minimize their score. Once everyone has selected their opinion to a question, host will move the game forward to reveal how the opinions were chosen for that question. Based on everyone’s opinion, points are rewarded. If your chosen opinion was in majority, you will earn the highest points for that question and if your chosen opinion was in minority, you will earn the lowest points. Points tally up for subsequent questions."
          Images={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={game_play} className="img" style={{ marginTop: 10 }} />
            </div>
          )}
        />
        <Section
          title={"Earning the Points"}
          style={{
            padding: common_padding,
            background: "linear-gradient(180deg, #808AFF 0%, #BE1279 100%)",
          }}
          Images={() => (
            <div>
              <Paragraph
                text={
                  "For a question, points are earned based on how all other fellow QnOers select their opinions. Your score for a question is the support of all QnOers selecting the same opinion as you did for that question."
                }
              />
              <Paragraph
                text={"Example – In a group of 20, to a given question –"}
              />
              <ul style={{ color: "#FFF" }}>
                <li style={{ marginTop: 5 }}>Opinion ‘A’ – selected by 8</li>
                <li style={{ marginTop: 5 }}>Opinion ‘B’ – selected by 4</li>
                <li style={{ marginTop: 5 }}>Opinion ‘C’ – selected by 5</li>
                <li style={{ marginTop: 5 }}>Opinion ‘D’ – selected by 3</li>
              </ul>
              <Paragraph
                text={"Then below are the points earned for that question –"}
              />
              <ul style={{ color: "#FFF" }}>
                <li style={{ marginTop: 20 }}>
                  All 8 QnOers who selected opinion 'A’ will earn –{" "}
                  <strong>0.4</strong> points (8/20)
                </li>
                <li style={{ marginTop: 20 }}>
                  All 4 QnOers who selected opinion 'B’ will earn –{" "}
                  <strong>0.2</strong> points (4/20)
                </li>
                <li style={{ marginTop: 20 }}>
                  All 5 QnOers who selected opinion 'C’ will earn –{" "}
                  <strong>0.25</strong> points (5/20)
                </li>
                <li style={{ marginTop: 20 }}>
                  All 3 QnOers who selected opinion 'D’ will earn –{" "}
                  <strong>0.15</strong> points (3/20)
                </li>
              </ul>
            </div>
          )}
        />
        <FAQ />
      </div>
    </>
  );
};

export default Play;
