import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  Edit,
  EditButton,
  useNotify,
  DateField,
  DateTimeInput,
  useRecordContext,
  useRefresh,
  Button,
  FunctionField,
  usePermissions,
  useRedirect,
  NumberField,
} from "react-admin";
import CricketWeekIcon from "@mui/icons-material/SportsCricket";
import { app } from "../constants";
import { getCloudFunctionUrl } from "../trades";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const CloseWeek = () => {
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const closeWeek = async () => {
    if (record.end_date > new Date()) {
      return notify("You cannot clos this week this time.", { type: "error" });
    }
    setLoading(true);
    let url = getCloudFunctionUrl() + "/closeWeek?week_id=" + record.id;
    Promise.resolve(axios.get(url))
      .then(() => {
        refresh();
      })
      .catch(() => {
        refresh();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (record.closed) return "Closed";
  return (
    <Button onClick={closeWeek} disabled={record.end_date > new Date()}>
      {loading ? <CircularProgress size={20} /> : "Close Week Now"}
    </Button>
  );
};
const CricketWeekList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      {...(permissions?.role == app.ADMIN_ROLES.YOUTUBER && {
        hasCreate: false,
        hasEdit: false,
      })}
    >
      <Datagrid bulkActionButtons={false} size="large">
        <TextField source="name" />
        <NumberField source="total_trades" label="Total Trades" emptyText="0" />
        <DateField showTime source="start_date" />
        <DateField showTime source="end_date" />
        <CloseWeek />
        <FunctionField
          render={(record) =>
            record.closed || permissions?.role == app.ADMIN_ROLES.YOUTUBER ? (
              "--"
            ) : (
              <EditButton />
            )
          }
        />
      </Datagrid>
    </List>
  );
};
const CricketWeekForm = ({ edit }) => {
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  useEffect(() => {
    if (permissions?.role == app.ADMIN_ROLES.YOUTUBER) redirect("list");
  }, [permissions]);
  return (
    <SimpleForm>
      <TextInput source="name" {...app.inputAttrs} label="Name" />
      {!edit && (
        <DateTimeInput
          source="start_date"
          {...app.inputAttrs}
          label="Start Date of Week"
        />
      )}
      <DateTimeInput
        source="end_date"
        {...app.inputAttrs}
        label="End Date of Week"
      />
    </SimpleForm>
  );
};
const CricketWeekCreate = () => {
  const notify = useNotify();
  const transformData = async (_data) => {
    let data = { ..._data };
    if (data?.start_date > data?.end_date) {
      notify("End date must be greater than start date.", { type: "error" });
      return Promise.reject();
    }
    return data;
  };
  return (
    <Create transform={transformData}>
      <CricketWeekForm />
    </Create>
  );
};
const CricketWeekEdit = () => {
  return (
    <Edit>
      <CricketWeekForm edit />
    </Edit>
  );
};
export default {
  list: CricketWeekList,
  create: CricketWeekCreate,
  edit: CricketWeekEdit,
  name: "cricket_weeks",
  icon: CricketWeekIcon,
  label: "Cricket Weeks",
};
