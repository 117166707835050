import React from "react";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import BugIcon from "@mui/icons-material/BugReport";

const BugList = () => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField
        source="email"
        sx={{
          textTransform: "lowercase",
        }}
      />
      <TextField source="bug" />
    </Datagrid>
  </List>
);

export default {
  list: BugList,
  name: "bugs",
  icon: BugIcon,
  label: "Bugs",
};
