import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  ReferenceInput,
  SelectInput,
  ReferenceField,
} from "react-admin";
import TradeBannerIcon from "@mui/icons-material/ViewCarousel";
import { app } from "../constants";
import { FormControlLabel, Checkbox } from "@mui/material";

const TradeBannerList = () => (
  <List sort={{ field: "created_at", order: "ASC" }}>
    <Datagrid>
      <TextField source="title" />
      <ReferenceField
        source="trade_category"
        reference="trade_categories"
        label="Trade Category"
      >
        <TextField source="title" emptyText="All" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);
const TradeBannerForm = () => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <SimpleForm>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Banners For All?"
      />
      <TextInput source="title" {...app.inputAttrs} />
      {!checked && (
        <ReferenceInput source="trade_category" reference={"trade_categories"}>
          <SelectInput
            variant="outlined"
            label="Category"
            {...app.inputAttrs}
          />
        </ReferenceInput>
      )}
      <ImageInput
        source="images"
        label="Banner Images"
        multiple
        accept={["image/*"]}
      >
        <ImageField source="src" title="Image" />
      </ImageInput>
    </SimpleForm>
  );
};
const transformData = (data) => {
  if (!data.trade_category) data.trade_category = "all";
  return data;
};
const TradeBannerEdit = () => (
  <Edit transform={transformData}>
    <TradeBannerForm />
  </Edit>
);
const TradeBannerCreate = () => (
  <Create transform={transformData}>
    <TradeBannerForm />
  </Create>
);
export default {
  list: TradeBannerList,
  edit: TradeBannerEdit,
  create: TradeBannerCreate,
  name: "trade_league_banners",
  icon: TradeBannerIcon,
  label: "Trade League Banner",
};
